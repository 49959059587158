import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DriveCaroService } from './app/providers/drive-caro.service';
import { APIService } from './API.service';

/*
  Generated class for the CustomErrorHandlerProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class CustomErrorHandlerProvider {

    constructor(private _http: HttpClient, public driveCaroService: DriveCaroService, private api: APIService) { }

    handleError(error) {
        // your custom error handling logic
        this.loggError(error);
    }


    public loggError(err) {
        console.log('global error', err);
        const errStr = JSON.stringify(err, Object.getOwnPropertyNames(err));
        this.driveCaroService.errorMessages.push({ err: errStr });

        // console.log(this._sessionInfoService.errorMessages);

        const isErrExistsCount = this.driveCaroService.errorMessages.filter(x => x.err === errStr).length;
        // console.log(isErrExistsCount);

        if (isErrExistsCount > 0) {
            const body = {
                errorMessage: 'DCOSchool--->' + errStr,
                timeStamp: new Date(),
                loginUserData: JSON.stringify(this.driveCaroService.schoolDetails)
            }
            console.log('global errors', body);
            this.reportError(body);
        }
    }

    public reportError(body) {
        this.api.CreateErrorLogs(body).then(res => {
            console.log(res, 'schedule extended');
        }).catch(e => {
            console.log('error', e);
        })
    }

}
