/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateSchool: OnCreateSchoolSubscription;
  onUpdateSchool: OnUpdateSchoolSubscription;
  onDeleteSchool: OnDeleteSchoolSubscription;
  onCreateCar: OnCreateCarSubscription;
  onUpdateCar: OnUpdateCarSubscription;
  onDeleteCar: OnDeleteCarSubscription;
  onCreateCarSchedule: OnCreateCarScheduleSubscription;
  onUpdateCarSchedule: OnUpdateCarScheduleSubscription;
  onDeleteCarSchedule: OnDeleteCarScheduleSubscription;
  onCreateCarScheduleArchive: OnCreateCarScheduleArchiveSubscription;
  onUpdateCarScheduleArchive: OnUpdateCarScheduleArchiveSubscription;
  onDeleteCarScheduleArchive: OnDeleteCarScheduleArchiveSubscription;
  onCreateStudent: OnCreateStudentSubscription;
  onUpdateStudent: OnUpdateStudentSubscription;
  onDeleteStudent: OnDeleteStudentSubscription;
  onCreateCourse: OnCreateCourseSubscription;
  onUpdateCourse: OnUpdateCourseSubscription;
  onDeleteCourse: OnDeleteCourseSubscription;
  onCreateSlotAvailability: OnCreateSlotAvailabilitySubscription;
  onUpdateSlotAvailability: OnUpdateSlotAvailabilitySubscription;
  onDeleteSlotAvailability: OnDeleteSlotAvailabilitySubscription;
  onCreateCustomerRequests: OnCreateCustomerRequestsSubscription;
  onUpdateCustomerRequests: OnUpdateCustomerRequestsSubscription;
  onDeleteCustomerRequests: OnDeleteCustomerRequestsSubscription;
  onCreateVideos: OnCreateVideosSubscription;
  onUpdateVideos: OnUpdateVideosSubscription;
  onDeleteVideos: OnDeleteVideosSubscription;
  onCreateGroupVideoMapper: OnCreateGroupVideoMapperSubscription;
  onUpdateGroupVideoMapper: OnUpdateGroupVideoMapperSubscription;
  onDeleteGroupVideoMapper: OnDeleteGroupVideoMapperSubscription;
  onCreateAddress: OnCreateAddressSubscription;
  onUpdateAddress: OnUpdateAddressSubscription;
  onDeleteAddress: OnDeleteAddressSubscription;
  onCreatePayment: OnCreatePaymentSubscription;
  onUpdatePayment: OnUpdatePaymentSubscription;
  onDeletePayment: OnDeletePaymentSubscription;
  onCreateErrorLogs: OnCreateErrorLogsSubscription;
  onUpdateErrorLogs: OnUpdateErrorLogsSubscription;
  onDeleteErrorLogs: OnDeleteErrorLogsSubscription;
};

export type CreateSchoolInput = {
  id?: string | null;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schoolImg?: Array<string | null> | null;
  _version?: number | null;
};

export type ModelSchoolConditionInput = {
  name?: ModelStringInput | null;
  contactPerson?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  pickupDuration?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  ratingCount?: ModelIntInput | null;
  distance?: ModelStringInput | null;
  distanceFee?: ModelStringInput | null;
  kms?: ModelStringInput | null;
  days?: ModelStringInput | null;
  price?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  doorNumber?: ModelStringInput | null;
  area?: ModelStringInput | null;
  landmark?: ModelStringInput | null;
  pincode?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  locality?: ModelStringInput | null;
  establishedOn?: ModelStringInput | null;
  classroomTraining?: ModelBooleanInput | null;
  simulatorTraining?: ModelBooleanInput | null;
  schoolImg?: ModelStringInput | null;
  and?: Array<ModelSchoolConditionInput | null> | null;
  or?: Array<ModelSchoolConditionInput | null> | null;
  not?: ModelSchoolConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type School = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: ModelCarConnection | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: ModelCarScheduleConnection | null;
  scheduleArchive?: ModelCarScheduleArchiveConnection | null;
  course?: ModelCourseConnection | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: ModelSlotAvailabilityConnection | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCarConnection = {
  __typename: "ModelCarConnection";
  items: Array<Car | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Car = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: School | null;
  schedule?: ModelCarScheduleConnection | null;
  scheduleArchive?: ModelCarScheduleArchiveConnection | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: Course | null;
  slotAvailability?: ModelSlotAvailabilityConnection | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type ModelCarScheduleConnection = {
  __typename: "ModelCarScheduleConnection";
  items: Array<CarSchedule | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CarSchedule = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: Address | null;
  student?: Student | null;
  school?: School | null;
  courseStatus?: number | null;
  car?: Car | null;
  course?: Course | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type Address = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: Student | null;
  carSchedule?: ModelCarScheduleConnection | null;
  CarScheduleArchive?: ModelCarScheduleArchiveConnection | null;
  course?: Course | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type Student = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: ModelAddressConnection | null;
  course?: ModelCourseConnection | null;
  schedule?: ModelCarScheduleConnection | null;
  scheduleArchive?: ModelCarScheduleArchiveConnection | null;
  slotAvailability?: ModelSlotAvailabilityConnection | null;
  defaultAddressId?: string | null;
  car?: Car | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type ModelAddressConnection = {
  __typename: "ModelAddressConnection";
  items: Array<Address | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelCourseConnection = {
  __typename: "ModelCourseConnection";
  items: Array<Course | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type Course = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: Car | null;
  student?: Student | null;
  school?: School | null;
  carSchedule?: ModelCarScheduleConnection | null;
  payment?: Payment | null;
  CarScheduleArchive?: ModelCarScheduleArchiveConnection | null;
  address?: Address | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type Payment = {
  __typename: "Payment";
  id: string;
  course?: ModelCourseConnection | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ModelCarScheduleArchiveConnection = {
  __typename: "ModelCarScheduleArchiveConnection";
  items: Array<CarScheduleArchive | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type CarScheduleArchive = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: Address | null;
  student?: Student | null;
  school?: School | null;
  courseStatus?: number | null;
  car?: Car | null;
  course?: Course | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type ModelSlotAvailabilityConnection = {
  __typename: "ModelSlotAvailabilityConnection";
  items: Array<SlotAvailability | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SlotAvailability = {
  __typename: "SlotAvailability";
  school?: School | null;
  car?: Car | null;
  student?: Student | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type UpdateSchoolInput = {
  id: string;
  name?: string | null;
  contactPerson?: string | null;
  email?: string | null;
  phone?: string | null;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  staticMapAddress?: string | null;
  doorNumber?: string | null;
  area?: string | null;
  landmark?: string | null;
  pincode?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schoolImg?: Array<string | null> | null;
  _version?: number | null;
};

export type DeleteSchoolInput = {
  id: string;
  _version?: number | null;
};

export type CreateCarInput = {
  id?: string | null;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  _version?: number | null;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type ModelCarConditionInput = {
  carName?: ModelStringInput | null;
  carModel?: ModelStringInput | null;
  yearOfManf?: ModelIntInput | null;
  carNumber?: ModelStringInput | null;
  carPassword?: ModelStringInput | null;
  carImg?: ModelStringInput | null;
  rcCardImg?: ModelStringInput | null;
  carType?: ModelStringInput | null;
  dayStart?: ModelStringInput | null;
  dayEnd?: ModelStringInput | null;
  seatsOffered?: ModelIntInput | null;
  kms?: ModelIntInput | null;
  hoursPerDay?: ModelIntInput | null;
  totalDays?: ModelIntInput | null;
  validityDays?: ModelIntInput | null;
  forwardDays?: ModelIntInput | null;
  reverseDays?: ModelIntInput | null;
  classroomDays?: ModelIntInput | null;
  simulatorDays?: ModelIntInput | null;
  pricePrivate?: ModelStringInput | null;
  priceShared?: ModelStringInput | null;
  licenseCost?: ModelStringInput | null;
  pilotName?: ModelStringInput | null;
  pilotPhone?: ModelStringInput | null;
  slotDuration?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  and?: Array<ModelCarConditionInput | null> | null;
  or?: Array<ModelCarConditionInput | null> | null;
  not?: ModelCarConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  schoolCarListId?: ModelIDInput | null;
  carCourseId?: ModelIDInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateCarInput = {
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  _version?: number | null;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type DeleteCarInput = {
  id: string;
  _version?: number | null;
};

export type CreateCarScheduleInput = {
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  courseStatus?: number | null;
  rating?: string | null;
  classNumber?: number | null;
  id?: string | null;
  _version?: number | null;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type ModelCarScheduleConditionInput = {
  date?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  slotStatus?: ModelIntInput | null;
  kmsDriven?: ModelStringInput | null;
  pickedTime?: ModelStringInput | null;
  drivingStartedTime?: ModelStringInput | null;
  drivingCompletedTime?: ModelStringInput | null;
  droppedTime?: ModelStringInput | null;
  courseStatus?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  classNumber?: ModelIntInput | null;
  and?: Array<ModelCarScheduleConditionInput | null> | null;
  or?: Array<ModelCarScheduleConditionInput | null> | null;
  not?: ModelCarScheduleConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  schoolScheduleId?: ModelIDInput | null;
  carScheduleId?: ModelIDInput | null;
  studentScheduleId?: ModelIDInput | null;
  courseCarScheduleId?: ModelIDInput | null;
  addressCarScheduleId?: ModelIDInput | null;
};

export type UpdateCarScheduleInput = {
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  courseStatus?: number | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  _version?: number | null;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type DeleteCarScheduleInput = {
  id: string;
  _version?: number | null;
};

export type CreateCarScheduleArchiveInput = {
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  courseStatus?: number | null;
  rating?: string | null;
  id?: string | null;
  _version?: number | null;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type ModelCarScheduleArchiveConditionInput = {
  date?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  slotStatus?: ModelIntInput | null;
  kmsDriven?: ModelStringInput | null;
  pickedTime?: ModelStringInput | null;
  drivingStartedTime?: ModelStringInput | null;
  drivingCompletedTime?: ModelStringInput | null;
  droppedTime?: ModelStringInput | null;
  courseStatus?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  and?: Array<ModelCarScheduleArchiveConditionInput | null> | null;
  or?: Array<ModelCarScheduleArchiveConditionInput | null> | null;
  not?: ModelCarScheduleArchiveConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  schoolScheduleArchiveId?: ModelIDInput | null;
  carScheduleArchiveId?: ModelIDInput | null;
  studentScheduleArchiveId?: ModelIDInput | null;
  courseCarScheduleArchiveId?: ModelIDInput | null;
  addressCarScheduleArchiveId?: ModelIDInput | null;
};

export type UpdateCarScheduleArchiveInput = {
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  courseStatus?: number | null;
  rating?: string | null;
  id: string;
  _version?: number | null;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type DeleteCarScheduleArchiveInput = {
  id: string;
  _version?: number | null;
};

export type CreateStudentInput = {
  id?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  defaultAddressId?: string | null;
  _version?: number | null;
  studentCarId?: string | null;
};

export type ModelStudentConditionInput = {
  firstName?: ModelStringInput | null;
  middleName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  gender?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  dob?: ModelStringInput | null;
  city?: ModelStringInput | null;
  location?: ModelStringInput | null;
  profilePic?: ModelStringInput | null;
  defaultAddressId?: ModelStringInput | null;
  and?: Array<ModelStudentConditionInput | null> | null;
  or?: Array<ModelStudentConditionInput | null> | null;
  not?: ModelStudentConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  studentCarId?: ModelIDInput | null;
};

export type UpdateStudentInput = {
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone?: string | null;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  defaultAddressId?: string | null;
  _version?: number | null;
  studentCarId?: string | null;
};

export type DeleteStudentInput = {
  id: string;
  _version?: number | null;
};

export type CreateCourseInput = {
  id?: string | null;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  _version?: number | null;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type ModelCourseConditionInput = {
  courseName?: ModelStringInput | null;
  timeSlot?: ModelStringInput | null;
  status?: ModelStringInput | null;
  rating?: ModelStringInput | null;
  ratingCount?: ModelIntInput | null;
  arrivingBy?: ModelStringInput | null;
  lesson?: ModelStringInput | null;
  courseDay?: ModelIntInput | null;
  courseStart?: ModelStringInput | null;
  courseEnd?: ModelStringInput | null;
  videos?: ModelStringInput | null;
  totalClasses?: ModelIntInput | null;
  totalTime?: ModelIntInput | null;
  completedTime?: ModelIntInput | null;
  totalDistance?: ModelIntInput | null;
  completedClasses?: ModelIntInput | null;
  cancelledClasses?: ModelIntInput | null;
  missedClasses?: ModelIntInput | null;
  missedLimit?: ModelIntInput | null;
  cancelledLimit?: ModelIntInput | null;
  completedDistance?: ModelStringInput | null;
  isOfflineBooking?: ModelBooleanInput | null;
  courseCompleted?: ModelBooleanInput | null;
  courseSuspended?: ModelBooleanInput | null;
  and?: Array<ModelCourseConditionInput | null> | null;
  or?: Array<ModelCourseConditionInput | null> | null;
  not?: ModelCourseConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  schoolCourseId?: ModelIDInput | null;
  studentCourseId?: ModelIDInput | null;
  paymentCourseId?: ModelIDInput | null;
  courseCarId?: ModelIDInput | null;
  courseAddressId?: ModelIDInput | null;
};

export type UpdateCourseInput = {
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  _version?: number | null;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type DeleteCourseInput = {
  id: string;
  _version?: number | null;
};

export type CreateSlotAvailabilityInput = {
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id?: string | null;
  _version?: number | null;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type ModelSlotAvailabilityConditionInput = {
  timeSlot?: ModelStringInput | null;
  isBooked?: ModelBooleanInput | null;
  courseEnd?: ModelStringInput | null;
  and?: Array<ModelSlotAvailabilityConditionInput | null> | null;
  or?: Array<ModelSlotAvailabilityConditionInput | null> | null;
  not?: ModelSlotAvailabilityConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  schoolSlotAvailabilityId?: ModelIDInput | null;
  carSlotAvailabilityId?: ModelIDInput | null;
  studentSlotAvailabilityId?: ModelIDInput | null;
};

export type UpdateSlotAvailabilityInput = {
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  _version?: number | null;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type DeleteSlotAvailabilityInput = {
  id: string;
  _version?: number | null;
};

export type CreateCustomerRequestsInput = {
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id?: string | null;
  _version?: number | null;
};

export type ModelCustomerRequestsConditionInput = {
  name?: ModelStringInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelCustomerRequestsConditionInput | null> | null;
  or?: Array<ModelCustomerRequestsConditionInput | null> | null;
  not?: ModelCustomerRequestsConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type CustomerRequests = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCustomerRequestsInput = {
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  _version?: number | null;
};

export type DeleteCustomerRequestsInput = {
  id: string;
  _version?: number | null;
};

export type CreateVideosInput = {
  id?: string | null;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  _version?: number | null;
};

export type ModelVideosConditionInput = {
  videoSequenceId?: ModelIntInput | null;
  groupTagId?: ModelIntInput | null;
  title?: ModelStringInput | null;
  desc?: ModelStringInput | null;
  url?: ModelStringInput | null;
  posterUrl?: ModelStringInput | null;
  and?: Array<ModelVideosConditionInput | null> | null;
  or?: Array<ModelVideosConditionInput | null> | null;
  not?: ModelVideosConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type Videos = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVideosInput = {
  id?: string | null;
  videoId: number;
  videoSequenceId?: number | null;
  groupTagId?: number | null;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  _version?: number | null;
};

export type DeleteVideosInput = {
  videoId: number;
  _version?: number | null;
};

export type CreateGroupVideoMapperInput = {
  id?: string | null;
  groupTagId: number;
  tagName?: string | null;
  _version?: number | null;
};

export type ModelGroupVideoMapperConditionInput = {
  groupTagId?: ModelIntInput | null;
  tagName?: ModelStringInput | null;
  and?: Array<ModelGroupVideoMapperConditionInput | null> | null;
  or?: Array<ModelGroupVideoMapperConditionInput | null> | null;
  not?: ModelGroupVideoMapperConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type groupVideoMapper = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateGroupVideoMapperInput = {
  id: string;
  groupTagId?: number | null;
  tagName?: string | null;
  _version?: number | null;
};

export type DeleteGroupVideoMapperInput = {
  id: string;
  _version?: number | null;
};

export type CreateAddressInput = {
  id?: string | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  _version?: number | null;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type ModelAddressConditionInput = {
  staticMapAddress?: ModelStringInput | null;
  doorNumber?: ModelStringInput | null;
  area?: ModelStringInput | null;
  locality?: ModelStringInput | null;
  landmark?: ModelStringInput | null;
  label?: ModelStringInput | null;
  pincode?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  isDefault?: ModelBooleanInput | null;
  and?: Array<ModelAddressConditionInput | null> | null;
  or?: Array<ModelAddressConditionInput | null> | null;
  not?: ModelAddressConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  studentAddressListId?: ModelIDInput | null;
  addressCourseId?: ModelIDInput | null;
};

export type UpdateAddressInput = {
  id: string;
  staticMapAddress?: string | null;
  doorNumber?: string | null;
  area?: string | null;
  locality?: string | null;
  landmark?: string | null;
  label?: string | null;
  pincode?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  _version?: number | null;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type DeleteAddressInput = {
  id: string;
  _version?: number | null;
};

export type CreatePaymentInput = {
  id?: string | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  _version?: number | null;
};

export type ModelPaymentConditionInput = {
  amountReceived?: ModelIntInput | null;
  courseAmount?: ModelIntInput | null;
  status?: ModelStringInput | null;
  paymentDate?: ModelStringInput | null;
  tax?: ModelIntInput | null;
  and?: Array<ModelPaymentConditionInput | null> | null;
  or?: Array<ModelPaymentConditionInput | null> | null;
  not?: ModelPaymentConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type UpdatePaymentInput = {
  id: string;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  _version?: number | null;
};

export type DeletePaymentInput = {
  id: string;
  _version?: number | null;
};

export type CreateErrorLogsInput = {
  id?: string | null;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  _version?: number | null;
};

export type ModelErrorLogsConditionInput = {
  errorMessage?: ModelStringInput | null;
  timeStamp?: ModelStringInput | null;
  loginUserData?: ModelStringInput | null;
  and?: Array<ModelErrorLogsConditionInput | null> | null;
  or?: Array<ModelErrorLogsConditionInput | null> | null;
  not?: ModelErrorLogsConditionInput | null;
  _deleted?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
};

export type ErrorLogs = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateErrorLogsInput = {
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  _version?: number | null;
};

export type DeleteErrorLogsInput = {
  id: string;
  _version?: number | null;
};

export type ModelSchoolFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  contactPerson?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  pickupDuration?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  ratingCount?: ModelIntInput | null;
  distance?: ModelStringInput | null;
  distanceFee?: ModelStringInput | null;
  kms?: ModelStringInput | null;
  days?: ModelStringInput | null;
  price?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  doorNumber?: ModelStringInput | null;
  area?: ModelStringInput | null;
  landmark?: ModelStringInput | null;
  pincode?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  locality?: ModelStringInput | null;
  establishedOn?: ModelStringInput | null;
  classroomTraining?: ModelBooleanInput | null;
  simulatorTraining?: ModelBooleanInput | null;
  schoolImg?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSchoolFilterInput | null> | null;
  or?: Array<ModelSchoolFilterInput | null> | null;
  not?: ModelSchoolFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSchoolConnection = {
  __typename: "ModelSchoolConnection";
  items: Array<School | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelCarFilterInput = {
  id?: ModelIDInput | null;
  carName?: ModelStringInput | null;
  carModel?: ModelStringInput | null;
  yearOfManf?: ModelIntInput | null;
  carNumber?: ModelStringInput | null;
  carPassword?: ModelStringInput | null;
  carImg?: ModelStringInput | null;
  rcCardImg?: ModelStringInput | null;
  carType?: ModelStringInput | null;
  dayStart?: ModelStringInput | null;
  dayEnd?: ModelStringInput | null;
  seatsOffered?: ModelIntInput | null;
  kms?: ModelIntInput | null;
  hoursPerDay?: ModelIntInput | null;
  totalDays?: ModelIntInput | null;
  validityDays?: ModelIntInput | null;
  forwardDays?: ModelIntInput | null;
  reverseDays?: ModelIntInput | null;
  classroomDays?: ModelIntInput | null;
  simulatorDays?: ModelIntInput | null;
  pricePrivate?: ModelStringInput | null;
  priceShared?: ModelStringInput | null;
  licenseCost?: ModelStringInput | null;
  pilotName?: ModelStringInput | null;
  pilotPhone?: ModelStringInput | null;
  slotDuration?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarFilterInput | null> | null;
  or?: Array<ModelCarFilterInput | null> | null;
  not?: ModelCarFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  schoolCarListId?: ModelIDInput | null;
  carCourseId?: ModelIDInput | null;
};

export type ModelCarScheduleFilterInput = {
  date?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  slotStatus?: ModelIntInput | null;
  kmsDriven?: ModelStringInput | null;
  pickedTime?: ModelStringInput | null;
  drivingStartedTime?: ModelStringInput | null;
  drivingCompletedTime?: ModelStringInput | null;
  droppedTime?: ModelStringInput | null;
  courseStatus?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  classNumber?: ModelIntInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarScheduleFilterInput | null> | null;
  or?: Array<ModelCarScheduleFilterInput | null> | null;
  not?: ModelCarScheduleFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  schoolScheduleId?: ModelIDInput | null;
  carScheduleId?: ModelIDInput | null;
  studentScheduleId?: ModelIDInput | null;
  courseCarScheduleId?: ModelIDInput | null;
  addressCarScheduleId?: ModelIDInput | null;
};

export type ModelCarScheduleArchiveFilterInput = {
  date?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  slotStatus?: ModelIntInput | null;
  kmsDriven?: ModelStringInput | null;
  pickedTime?: ModelStringInput | null;
  drivingStartedTime?: ModelStringInput | null;
  drivingCompletedTime?: ModelStringInput | null;
  droppedTime?: ModelStringInput | null;
  courseStatus?: ModelIntInput | null;
  rating?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCarScheduleArchiveFilterInput | null> | null;
  or?: Array<ModelCarScheduleArchiveFilterInput | null> | null;
  not?: ModelCarScheduleArchiveFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  schoolScheduleArchiveId?: ModelIDInput | null;
  carScheduleArchiveId?: ModelIDInput | null;
  studentScheduleArchiveId?: ModelIDInput | null;
  courseCarScheduleArchiveId?: ModelIDInput | null;
  addressCarScheduleArchiveId?: ModelIDInput | null;
};

export type ModelStudentFilterInput = {
  id?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  middleName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  gender?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  dob?: ModelStringInput | null;
  city?: ModelStringInput | null;
  location?: ModelStringInput | null;
  profilePic?: ModelStringInput | null;
  defaultAddressId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelStudentFilterInput | null> | null;
  or?: Array<ModelStudentFilterInput | null> | null;
  not?: ModelStudentFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  studentCarId?: ModelIDInput | null;
};

export type ModelStudentConnection = {
  __typename: "ModelStudentConnection";
  items: Array<Student | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelCourseFilterInput = {
  id?: ModelIDInput | null;
  courseName?: ModelStringInput | null;
  timeSlot?: ModelStringInput | null;
  status?: ModelStringInput | null;
  rating?: ModelStringInput | null;
  ratingCount?: ModelIntInput | null;
  arrivingBy?: ModelStringInput | null;
  lesson?: ModelStringInput | null;
  courseDay?: ModelIntInput | null;
  courseStart?: ModelStringInput | null;
  courseEnd?: ModelStringInput | null;
  videos?: ModelStringInput | null;
  totalClasses?: ModelIntInput | null;
  totalTime?: ModelIntInput | null;
  completedTime?: ModelIntInput | null;
  totalDistance?: ModelIntInput | null;
  completedClasses?: ModelIntInput | null;
  cancelledClasses?: ModelIntInput | null;
  missedClasses?: ModelIntInput | null;
  missedLimit?: ModelIntInput | null;
  cancelledLimit?: ModelIntInput | null;
  completedDistance?: ModelStringInput | null;
  isOfflineBooking?: ModelBooleanInput | null;
  courseCompleted?: ModelBooleanInput | null;
  courseSuspended?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCourseFilterInput | null> | null;
  or?: Array<ModelCourseFilterInput | null> | null;
  not?: ModelCourseFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  schoolCourseId?: ModelIDInput | null;
  studentCourseId?: ModelIDInput | null;
  paymentCourseId?: ModelIDInput | null;
  courseCarId?: ModelIDInput | null;
  courseAddressId?: ModelIDInput | null;
};

export type ModelSlotAvailabilityFilterInput = {
  timeSlot?: ModelStringInput | null;
  isBooked?: ModelBooleanInput | null;
  courseEnd?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSlotAvailabilityFilterInput | null> | null;
  or?: Array<ModelSlotAvailabilityFilterInput | null> | null;
  not?: ModelSlotAvailabilityFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  schoolSlotAvailabilityId?: ModelIDInput | null;
  carSlotAvailabilityId?: ModelIDInput | null;
  studentSlotAvailabilityId?: ModelIDInput | null;
};

export type ModelCustomerRequestsFilterInput = {
  name?: ModelStringInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  staticMapAddress?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  email?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelCustomerRequestsFilterInput | null> | null;
  or?: Array<ModelCustomerRequestsFilterInput | null> | null;
  not?: ModelCustomerRequestsFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelCustomerRequestsConnection = {
  __typename: "ModelCustomerRequestsConnection";
  items: Array<CustomerRequests | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelVideosFilterInput = {
  id?: ModelIDInput | null;
  videoId?: ModelIntInput | null;
  videoSequenceId?: ModelIntInput | null;
  groupTagId?: ModelIntInput | null;
  title?: ModelStringInput | null;
  desc?: ModelStringInput | null;
  url?: ModelStringInput | null;
  posterUrl?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelVideosFilterInput | null> | null;
  or?: Array<ModelVideosFilterInput | null> | null;
  not?: ModelVideosFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelVideosConnection = {
  __typename: "ModelVideosConnection";
  items: Array<Videos | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelGroupVideoMapperFilterInput = {
  id?: ModelIDInput | null;
  groupTagId?: ModelIntInput | null;
  tagName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelGroupVideoMapperFilterInput | null> | null;
  or?: Array<ModelGroupVideoMapperFilterInput | null> | null;
  not?: ModelGroupVideoMapperFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelGroupVideoMapperConnection = {
  __typename: "ModelGroupVideoMapperConnection";
  items: Array<groupVideoMapper | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelAddressFilterInput = {
  id?: ModelIDInput | null;
  staticMapAddress?: ModelStringInput | null;
  doorNumber?: ModelStringInput | null;
  area?: ModelStringInput | null;
  locality?: ModelStringInput | null;
  landmark?: ModelStringInput | null;
  label?: ModelStringInput | null;
  pincode?: ModelIntInput | null;
  latitude?: ModelStringInput | null;
  longitude?: ModelStringInput | null;
  isDefault?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelAddressFilterInput | null> | null;
  or?: Array<ModelAddressFilterInput | null> | null;
  not?: ModelAddressFilterInput | null;
  _deleted?: ModelBooleanInput | null;
  studentAddressListId?: ModelIDInput | null;
  addressCourseId?: ModelIDInput | null;
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null;
  amountReceived?: ModelIntInput | null;
  courseAmount?: ModelIntInput | null;
  status?: ModelStringInput | null;
  paymentDate?: ModelStringInput | null;
  tax?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelPaymentFilterInput | null> | null;
  or?: Array<ModelPaymentFilterInput | null> | null;
  not?: ModelPaymentFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection";
  items: Array<Payment | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelErrorLogsFilterInput = {
  id?: ModelIDInput | null;
  errorMessage?: ModelStringInput | null;
  timeStamp?: ModelStringInput | null;
  loginUserData?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelErrorLogsFilterInput | null> | null;
  or?: Array<ModelErrorLogsFilterInput | null> | null;
  not?: ModelErrorLogsFilterInput | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelErrorLogsConnection = {
  __typename: "ModelErrorLogsConnection";
  items: Array<ErrorLogs | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type ModelSubscriptionSchoolFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  name?: ModelSubscriptionStringInput | null;
  contactPerson?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  pickupDuration?: ModelSubscriptionIntInput | null;
  rating?: ModelSubscriptionStringInput | null;
  ratingCount?: ModelSubscriptionIntInput | null;
  distance?: ModelSubscriptionStringInput | null;
  distanceFee?: ModelSubscriptionStringInput | null;
  kms?: ModelSubscriptionStringInput | null;
  days?: ModelSubscriptionStringInput | null;
  price?: ModelSubscriptionStringInput | null;
  staticMapAddress?: ModelSubscriptionStringInput | null;
  doorNumber?: ModelSubscriptionStringInput | null;
  area?: ModelSubscriptionStringInput | null;
  landmark?: ModelSubscriptionStringInput | null;
  pincode?: ModelSubscriptionIntInput | null;
  latitude?: ModelSubscriptionStringInput | null;
  longitude?: ModelSubscriptionStringInput | null;
  locality?: ModelSubscriptionStringInput | null;
  establishedOn?: ModelSubscriptionStringInput | null;
  classroomTraining?: ModelSubscriptionBooleanInput | null;
  simulatorTraining?: ModelSubscriptionBooleanInput | null;
  schoolImg?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSchoolFilterInput | null> | null;
  or?: Array<ModelSubscriptionSchoolFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  schoolCarListId?: ModelSubscriptionIDInput | null;
  schoolScheduleId?: ModelSubscriptionIDInput | null;
  schoolScheduleArchiveId?: ModelSubscriptionIDInput | null;
  schoolCourseId?: ModelSubscriptionIDInput | null;
  schoolSlotAvailabilityId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionCarFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  carName?: ModelSubscriptionStringInput | null;
  carModel?: ModelSubscriptionStringInput | null;
  yearOfManf?: ModelSubscriptionIntInput | null;
  carNumber?: ModelSubscriptionStringInput | null;
  carPassword?: ModelSubscriptionStringInput | null;
  carImg?: ModelSubscriptionStringInput | null;
  rcCardImg?: ModelSubscriptionStringInput | null;
  carType?: ModelSubscriptionStringInput | null;
  dayStart?: ModelSubscriptionStringInput | null;
  dayEnd?: ModelSubscriptionStringInput | null;
  seatsOffered?: ModelSubscriptionIntInput | null;
  kms?: ModelSubscriptionIntInput | null;
  hoursPerDay?: ModelSubscriptionIntInput | null;
  totalDays?: ModelSubscriptionIntInput | null;
  validityDays?: ModelSubscriptionIntInput | null;
  forwardDays?: ModelSubscriptionIntInput | null;
  reverseDays?: ModelSubscriptionIntInput | null;
  classroomDays?: ModelSubscriptionIntInput | null;
  simulatorDays?: ModelSubscriptionIntInput | null;
  pricePrivate?: ModelSubscriptionStringInput | null;
  priceShared?: ModelSubscriptionStringInput | null;
  licenseCost?: ModelSubscriptionStringInput | null;
  pilotName?: ModelSubscriptionStringInput | null;
  pilotPhone?: ModelSubscriptionStringInput | null;
  slotDuration?: ModelSubscriptionIntInput | null;
  latitude?: ModelSubscriptionStringInput | null;
  longitude?: ModelSubscriptionStringInput | null;
  staticMapAddress?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCarFilterInput | null> | null;
  or?: Array<ModelSubscriptionCarFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  carScheduleId?: ModelSubscriptionIDInput | null;
  carScheduleArchiveId?: ModelSubscriptionIDInput | null;
  carSlotAvailabilityId?: ModelSubscriptionIDInput | null;
  carCourseId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionCarScheduleFilterInput = {
  date?: ModelSubscriptionStringInput | null;
  start?: ModelSubscriptionStringInput | null;
  end?: ModelSubscriptionStringInput | null;
  slotStatus?: ModelSubscriptionIntInput | null;
  kmsDriven?: ModelSubscriptionStringInput | null;
  pickedTime?: ModelSubscriptionStringInput | null;
  drivingStartedTime?: ModelSubscriptionStringInput | null;
  drivingCompletedTime?: ModelSubscriptionStringInput | null;
  droppedTime?: ModelSubscriptionStringInput | null;
  courseStatus?: ModelSubscriptionIntInput | null;
  rating?: ModelSubscriptionStringInput | null;
  classNumber?: ModelSubscriptionIntInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCarScheduleFilterInput | null> | null;
  or?: Array<ModelSubscriptionCarScheduleFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionCarScheduleArchiveFilterInput = {
  date?: ModelSubscriptionStringInput | null;
  start?: ModelSubscriptionStringInput | null;
  end?: ModelSubscriptionStringInput | null;
  slotStatus?: ModelSubscriptionIntInput | null;
  kmsDriven?: ModelSubscriptionStringInput | null;
  pickedTime?: ModelSubscriptionStringInput | null;
  drivingStartedTime?: ModelSubscriptionStringInput | null;
  drivingCompletedTime?: ModelSubscriptionStringInput | null;
  droppedTime?: ModelSubscriptionStringInput | null;
  courseStatus?: ModelSubscriptionIntInput | null;
  rating?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCarScheduleArchiveFilterInput | null> | null;
  or?: Array<ModelSubscriptionCarScheduleArchiveFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionStudentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  firstName?: ModelSubscriptionStringInput | null;
  middleName?: ModelSubscriptionStringInput | null;
  lastName?: ModelSubscriptionStringInput | null;
  gender?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  dob?: ModelSubscriptionStringInput | null;
  city?: ModelSubscriptionStringInput | null;
  location?: ModelSubscriptionStringInput | null;
  profilePic?: ModelSubscriptionStringInput | null;
  defaultAddressId?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionStudentFilterInput | null> | null;
  or?: Array<ModelSubscriptionStudentFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  studentAddressListId?: ModelSubscriptionIDInput | null;
  studentCourseId?: ModelSubscriptionIDInput | null;
  studentScheduleId?: ModelSubscriptionIDInput | null;
  studentScheduleArchiveId?: ModelSubscriptionIDInput | null;
  studentSlotAvailabilityId?: ModelSubscriptionIDInput | null;
  studentCarId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionCourseFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  courseName?: ModelSubscriptionStringInput | null;
  timeSlot?: ModelSubscriptionStringInput | null;
  status?: ModelSubscriptionStringInput | null;
  rating?: ModelSubscriptionStringInput | null;
  ratingCount?: ModelSubscriptionIntInput | null;
  arrivingBy?: ModelSubscriptionStringInput | null;
  lesson?: ModelSubscriptionStringInput | null;
  courseDay?: ModelSubscriptionIntInput | null;
  courseStart?: ModelSubscriptionStringInput | null;
  courseEnd?: ModelSubscriptionStringInput | null;
  videos?: ModelSubscriptionStringInput | null;
  totalClasses?: ModelSubscriptionIntInput | null;
  totalTime?: ModelSubscriptionIntInput | null;
  completedTime?: ModelSubscriptionIntInput | null;
  totalDistance?: ModelSubscriptionIntInput | null;
  completedClasses?: ModelSubscriptionIntInput | null;
  cancelledClasses?: ModelSubscriptionIntInput | null;
  missedClasses?: ModelSubscriptionIntInput | null;
  missedLimit?: ModelSubscriptionIntInput | null;
  cancelledLimit?: ModelSubscriptionIntInput | null;
  completedDistance?: ModelSubscriptionStringInput | null;
  isOfflineBooking?: ModelSubscriptionBooleanInput | null;
  courseCompleted?: ModelSubscriptionBooleanInput | null;
  courseSuspended?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCourseFilterInput | null> | null;
  or?: Array<ModelSubscriptionCourseFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  courseCarScheduleId?: ModelSubscriptionIDInput | null;
  courseCarScheduleArchiveId?: ModelSubscriptionIDInput | null;
  courseCarId?: ModelSubscriptionIDInput | null;
  courseAddressId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionSlotAvailabilityFilterInput = {
  timeSlot?: ModelSubscriptionStringInput | null;
  isBooked?: ModelSubscriptionBooleanInput | null;
  courseEnd?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionSlotAvailabilityFilterInput | null> | null;
  or?: Array<ModelSubscriptionSlotAvailabilityFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionCustomerRequestsFilterInput = {
  name?: ModelSubscriptionStringInput | null;
  latitude?: ModelSubscriptionStringInput | null;
  longitude?: ModelSubscriptionStringInput | null;
  staticMapAddress?: ModelSubscriptionStringInput | null;
  phone?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  notes?: ModelSubscriptionStringInput | null;
  id?: ModelSubscriptionIDInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCustomerRequestsFilterInput | null> | null;
  or?: Array<ModelSubscriptionCustomerRequestsFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionVideosFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  videoId?: ModelSubscriptionIntInput | null;
  videoSequenceId?: ModelSubscriptionIntInput | null;
  groupTagId?: ModelSubscriptionIntInput | null;
  title?: ModelSubscriptionStringInput | null;
  desc?: ModelSubscriptionStringInput | null;
  url?: ModelSubscriptionStringInput | null;
  posterUrl?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionVideosFilterInput | null> | null;
  or?: Array<ModelSubscriptionVideosFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionGroupVideoMapperFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  groupTagId?: ModelSubscriptionIntInput | null;
  tagName?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionGroupVideoMapperFilterInput | null> | null;
  or?: Array<ModelSubscriptionGroupVideoMapperFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type ModelSubscriptionAddressFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  staticMapAddress?: ModelSubscriptionStringInput | null;
  doorNumber?: ModelSubscriptionStringInput | null;
  area?: ModelSubscriptionStringInput | null;
  locality?: ModelSubscriptionStringInput | null;
  landmark?: ModelSubscriptionStringInput | null;
  label?: ModelSubscriptionStringInput | null;
  pincode?: ModelSubscriptionIntInput | null;
  latitude?: ModelSubscriptionStringInput | null;
  longitude?: ModelSubscriptionStringInput | null;
  isDefault?: ModelSubscriptionBooleanInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionAddressFilterInput | null> | null;
  or?: Array<ModelSubscriptionAddressFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  addressCarScheduleId?: ModelSubscriptionIDInput | null;
  addressCarScheduleArchiveId?: ModelSubscriptionIDInput | null;
  addressCourseId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionPaymentFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  amountReceived?: ModelSubscriptionIntInput | null;
  courseAmount?: ModelSubscriptionIntInput | null;
  status?: ModelSubscriptionStringInput | null;
  paymentDate?: ModelSubscriptionStringInput | null;
  tax?: ModelSubscriptionIntInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionPaymentFilterInput | null> | null;
  or?: Array<ModelSubscriptionPaymentFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
  paymentCourseId?: ModelSubscriptionIDInput | null;
};

export type ModelSubscriptionErrorLogsFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  errorMessage?: ModelSubscriptionStringInput | null;
  timeStamp?: ModelSubscriptionStringInput | null;
  loginUserData?: ModelSubscriptionStringInput | null;
  createdAt?: ModelSubscriptionStringInput | null;
  updatedAt?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionErrorLogsFilterInput | null> | null;
  or?: Array<ModelSubscriptionErrorLogsFilterInput | null> | null;
  _deleted?: ModelBooleanInput | null;
};

export type CreateSchoolMutation = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateSchoolMutation = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteSchoolMutation = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateCarMutation = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type UpdateCarMutation = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type DeleteCarMutation = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type CreateCarScheduleMutation = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type UpdateCarScheduleMutation = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type DeleteCarScheduleMutation = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type CreateCarScheduleArchiveMutation = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type UpdateCarScheduleArchiveMutation = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type DeleteCarScheduleArchiveMutation = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type CreateStudentMutation = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type UpdateStudentMutation = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type DeleteStudentMutation = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type CreateCourseMutation = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type UpdateCourseMutation = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type DeleteCourseMutation = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type CreateSlotAvailabilityMutation = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type UpdateSlotAvailabilityMutation = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type DeleteSlotAvailabilityMutation = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type CreateCustomerRequestsMutation = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateCustomerRequestsMutation = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteCustomerRequestsMutation = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateVideosMutation = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateVideosMutation = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteVideosMutation = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateGroupVideoMapperMutation = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateGroupVideoMapperMutation = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteGroupVideoMapperMutation = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateAddressMutation = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type UpdateAddressMutation = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type DeleteAddressMutation = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type CreatePaymentMutation = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdatePaymentMutation = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeletePaymentMutation = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type CreateErrorLogsMutation = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type UpdateErrorLogsMutation = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type DeleteErrorLogsMutation = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type GetSchoolQuery = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListSchoolsQuery = {
  __typename: "ModelSchoolConnection";
  items: Array<{
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSchoolsQuery = {
  __typename: "ModelSchoolConnection";
  items: Array<{
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCarQuery = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type ListCarsQuery = {
  __typename: "ModelCarConnection";
  items: Array<{
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCarsQuery = {
  __typename: "ModelCarConnection";
  items: Array<{
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCarScheduleQuery = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type ListCarSchedulesQuery = {
  __typename: "ModelCarScheduleConnection";
  items: Array<{
    __typename: "CarSchedule";
    date?: string | null;
    start?: string | null;
    end?: string | null;
    slotStatus?: number | null;
    kmsDriven?: string | null;
    pickedTime?: string | null;
    drivingStartedTime?: string | null;
    drivingCompletedTime?: string | null;
    droppedTime?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    courseStatus?: number | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    rating?: string | null;
    classNumber?: number | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolScheduleId?: string | null;
    carScheduleId?: string | null;
    studentScheduleId?: string | null;
    courseCarScheduleId?: string | null;
    addressCarScheduleId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCarSchedulesQuery = {
  __typename: "ModelCarScheduleConnection";
  items: Array<{
    __typename: "CarSchedule";
    date?: string | null;
    start?: string | null;
    end?: string | null;
    slotStatus?: number | null;
    kmsDriven?: string | null;
    pickedTime?: string | null;
    drivingStartedTime?: string | null;
    drivingCompletedTime?: string | null;
    droppedTime?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    courseStatus?: number | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    rating?: string | null;
    classNumber?: number | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolScheduleId?: string | null;
    carScheduleId?: string | null;
    studentScheduleId?: string | null;
    courseCarScheduleId?: string | null;
    addressCarScheduleId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCarScheduleArchiveQuery = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type ListCarScheduleArchivesQuery = {
  __typename: "ModelCarScheduleArchiveConnection";
  items: Array<{
    __typename: "CarScheduleArchive";
    date?: string | null;
    start?: string | null;
    end?: string | null;
    slotStatus?: number | null;
    kmsDriven?: string | null;
    pickedTime?: string | null;
    drivingStartedTime?: string | null;
    drivingCompletedTime?: string | null;
    droppedTime?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    courseStatus?: number | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    rating?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolScheduleArchiveId?: string | null;
    carScheduleArchiveId?: string | null;
    studentScheduleArchiveId?: string | null;
    courseCarScheduleArchiveId?: string | null;
    addressCarScheduleArchiveId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCarScheduleArchivesQuery = {
  __typename: "ModelCarScheduleArchiveConnection";
  items: Array<{
    __typename: "CarScheduleArchive";
    date?: string | null;
    start?: string | null;
    end?: string | null;
    slotStatus?: number | null;
    kmsDriven?: string | null;
    pickedTime?: string | null;
    drivingStartedTime?: string | null;
    drivingCompletedTime?: string | null;
    droppedTime?: string | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    courseStatus?: number | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    rating?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolScheduleArchiveId?: string | null;
    carScheduleArchiveId?: string | null;
    studentScheduleArchiveId?: string | null;
    courseCarScheduleArchiveId?: string | null;
    addressCarScheduleArchiveId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetStudentQuery = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type ListStudentsQuery = {
  __typename: "ModelStudentConnection";
  items: Array<{
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncStudentsQuery = {
  __typename: "ModelStudentConnection";
  items: Array<{
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCourseQuery = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type ListCoursesQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCoursesQuery = {
  __typename: "ModelCourseConnection";
  items: Array<{
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetSlotAvailabilityQuery = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type ListSlotAvailabilitiesQuery = {
  __typename: "ModelSlotAvailabilityConnection";
  items: Array<{
    __typename: "SlotAvailability";
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    timeSlot?: string | null;
    isBooked?: boolean | null;
    courseEnd?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolSlotAvailabilityId?: string | null;
    carSlotAvailabilityId?: string | null;
    studentSlotAvailabilityId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncSlotAvailabilitiesQuery = {
  __typename: "ModelSlotAvailabilityConnection";
  items: Array<{
    __typename: "SlotAvailability";
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    timeSlot?: string | null;
    isBooked?: boolean | null;
    courseEnd?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolSlotAvailabilityId?: string | null;
    carSlotAvailabilityId?: string | null;
    studentSlotAvailabilityId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetCustomerRequestsQuery = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListCustomerRequestsQuery = {
  __typename: "ModelCustomerRequestsConnection";
  items: Array<{
    __typename: "CustomerRequests";
    name?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    phone?: string | null;
    email?: string | null;
    notes?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncCustomerRequestsQuery = {
  __typename: "ModelCustomerRequestsConnection";
  items: Array<{
    __typename: "CustomerRequests";
    name?: string | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    phone?: string | null;
    email?: string | null;
    notes?: string | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetVideosQuery = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListVideosQuery = {
  __typename: "ModelVideosConnection";
  items: Array<{
    __typename: "Videos";
    id: string;
    videoId: number;
    videoSequenceId: number;
    groupTagId: number;
    title?: string | null;
    desc?: string | null;
    url?: string | null;
    posterUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncVideosQuery = {
  __typename: "ModelVideosConnection";
  items: Array<{
    __typename: "Videos";
    id: string;
    videoId: number;
    videoSequenceId: number;
    groupTagId: number;
    title?: string | null;
    desc?: string | null;
    url?: string | null;
    posterUrl?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetGroupVideoMapperQuery = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListGroupVideoMappersQuery = {
  __typename: "ModelGroupVideoMapperConnection";
  items: Array<{
    __typename: "groupVideoMapper";
    id: string;
    groupTagId: number;
    tagName?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncGroupVideoMappersQuery = {
  __typename: "ModelGroupVideoMapperConnection";
  items: Array<{
    __typename: "groupVideoMapper";
    id: string;
    groupTagId: number;
    tagName?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetAddressQuery = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type ListAddressesQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncAddressesQuery = {
  __typename: "ModelAddressConnection";
  items: Array<{
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetPaymentQuery = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListPaymentsQuery = {
  __typename: "ModelPaymentConnection";
  items: Array<{
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncPaymentsQuery = {
  __typename: "ModelPaymentConnection";
  items: Array<{
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type GetErrorLogsQuery = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type ListErrorLogsQuery = {
  __typename: "ModelErrorLogsConnection";
  items: Array<{
    __typename: "ErrorLogs";
    id: string;
    errorMessage?: string | null;
    timeStamp?: string | null;
    loginUserData?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type SyncErrorLogsQuery = {
  __typename: "ModelErrorLogsConnection";
  items: Array<{
    __typename: "ErrorLogs";
    id: string;
    errorMessage?: string | null;
    timeStamp?: string | null;
    loginUserData?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null>;
  nextToken?: string | null;
  startedAt?: number | null;
};

export type OnCreateSchoolSubscription = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateSchoolSubscription = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteSchoolSubscription = {
  __typename: "School";
  id: string;
  name: string;
  contactPerson: string;
  email?: string | null;
  phone: string;
  pickupDuration?: number | null;
  rating?: string | null;
  ratingCount?: number | null;
  distance?: string | null;
  distanceFee?: string | null;
  kms?: string | null;
  days?: string | null;
  price?: string | null;
  carList?: {
    __typename: "ModelCarConnection";
    items: Array<{
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  landmark: string;
  pincode: number;
  latitude?: string | null;
  longitude?: string | null;
  locality?: string | null;
  establishedOn?: string | null;
  classroomTraining?: boolean | null;
  simulatorTraining?: boolean | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schoolImg?: Array<string | null> | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateCarSubscription = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type OnUpdateCarSubscription = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type OnDeleteCarSubscription = {
  __typename: "Car";
  id: string;
  carName?: string | null;
  carModel?: string | null;
  yearOfManf?: number | null;
  carNumber?: string | null;
  carPassword?: string | null;
  carImg?: Array<string | null> | null;
  rcCardImg?: string | null;
  carType?: string | null;
  dayStart?: string | null;
  dayEnd?: string | null;
  seatsOffered?: number | null;
  kms?: number | null;
  hoursPerDay?: number | null;
  totalDays?: number | null;
  validityDays?: number | null;
  forwardDays?: number | null;
  reverseDays?: number | null;
  classroomDays?: number | null;
  simulatorDays?: number | null;
  pricePrivate?: string | null;
  priceShared?: string | null;
  licenseCost?: string | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  pilotName?: string | null;
  pilotPhone?: string | null;
  slotDuration?: number | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCarListId?: string | null;
  carCourseId?: string | null;
};

export type OnCreateCarScheduleSubscription = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type OnUpdateCarScheduleSubscription = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type OnDeleteCarScheduleSubscription = {
  __typename: "CarSchedule";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  classNumber?: number | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleId?: string | null;
  carScheduleId?: string | null;
  studentScheduleId?: string | null;
  courseCarScheduleId?: string | null;
  addressCarScheduleId?: string | null;
};

export type OnCreateCarScheduleArchiveSubscription = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type OnUpdateCarScheduleArchiveSubscription = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type OnDeleteCarScheduleArchiveSubscription = {
  __typename: "CarScheduleArchive";
  date?: string | null;
  start?: string | null;
  end?: string | null;
  slotStatus?: number | null;
  kmsDriven?: string | null;
  pickedTime?: string | null;
  drivingStartedTime?: string | null;
  drivingCompletedTime?: string | null;
  droppedTime?: string | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  courseStatus?: number | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  rating?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolScheduleArchiveId?: string | null;
  carScheduleArchiveId?: string | null;
  studentScheduleArchiveId?: string | null;
  courseCarScheduleArchiveId?: string | null;
  addressCarScheduleArchiveId?: string | null;
};

export type OnCreateStudentSubscription = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type OnUpdateStudentSubscription = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type OnDeleteStudentSubscription = {
  __typename: "Student";
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  gender?: string | null;
  email?: string | null;
  phone: string;
  dob?: string | null;
  city?: string | null;
  location?: string | null;
  profilePic?: string | null;
  addressList?: {
    __typename: "ModelAddressConnection";
    items: Array<{
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  schedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  scheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  slotAvailability?: {
    __typename: "ModelSlotAvailabilityConnection";
    items: Array<{
      __typename: "SlotAvailability";
      timeSlot?: string | null;
      isBooked?: boolean | null;
      courseEnd?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolSlotAvailabilityId?: string | null;
      carSlotAvailabilityId?: string | null;
      studentSlotAvailabilityId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  defaultAddressId?: string | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentCarId?: string | null;
};

export type OnCreateCourseSubscription = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type OnUpdateCourseSubscription = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type OnDeleteCourseSubscription = {
  __typename: "Course";
  id: string;
  courseName?: string | null;
  timeSlot?: string | null;
  status?: string | null;
  rating?: string | null;
  ratingCount?: number | null;
  arrivingBy?: string | null;
  lesson?: string | null;
  courseDay?: number | null;
  courseStart?: string | null;
  courseEnd?: string | null;
  videos?: Array<string | null> | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  payment?: {
    __typename: "Payment";
    id: string;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    amountReceived?: number | null;
    courseAmount?: number | null;
    status?: string | null;
    paymentDate?: string | null;
    tax?: number | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  address?: {
    __typename: "Address";
    id: string;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    locality?: string | null;
    landmark: string;
    label: string;
    pincode: number;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    isDefault?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentAddressListId?: string | null;
    addressCourseId?: string | null;
  } | null;
  totalClasses?: number | null;
  totalTime?: number | null;
  completedTime?: number | null;
  totalDistance?: number | null;
  completedClasses?: number | null;
  cancelledClasses?: number | null;
  missedClasses?: number | null;
  missedLimit?: number | null;
  cancelledLimit?: number | null;
  completedDistance?: string | null;
  isOfflineBooking?: boolean | null;
  courseCompleted?: boolean | null;
  courseSuspended?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolCourseId?: string | null;
  studentCourseId?: string | null;
  paymentCourseId?: string | null;
  courseCarId?: string | null;
  courseAddressId?: string | null;
};

export type OnCreateSlotAvailabilitySubscription = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type OnUpdateSlotAvailabilitySubscription = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type OnDeleteSlotAvailabilitySubscription = {
  __typename: "SlotAvailability";
  school?: {
    __typename: "School";
    id: string;
    name: string;
    contactPerson: string;
    email?: string | null;
    phone: string;
    pickupDuration?: number | null;
    rating?: string | null;
    ratingCount?: number | null;
    distance?: string | null;
    distanceFee?: string | null;
    kms?: string | null;
    days?: string | null;
    price?: string | null;
    carList?: {
      __typename: "ModelCarConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    staticMapAddress: string;
    doorNumber?: string | null;
    area: string;
    landmark: string;
    pincode: number;
    latitude?: string | null;
    longitude?: string | null;
    locality?: string | null;
    establishedOn?: string | null;
    classroomTraining?: boolean | null;
    simulatorTraining?: boolean | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schoolImg?: Array<string | null> | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
  } | null;
  car?: {
    __typename: "Car";
    id: string;
    carName?: string | null;
    carModel?: string | null;
    yearOfManf?: number | null;
    carNumber?: string | null;
    carPassword?: string | null;
    carImg?: Array<string | null> | null;
    rcCardImg?: string | null;
    carType?: string | null;
    dayStart?: string | null;
    dayEnd?: string | null;
    seatsOffered?: number | null;
    kms?: number | null;
    hoursPerDay?: number | null;
    totalDays?: number | null;
    validityDays?: number | null;
    forwardDays?: number | null;
    reverseDays?: number | null;
    classroomDays?: number | null;
    simulatorDays?: number | null;
    pricePrivate?: string | null;
    priceShared?: string | null;
    licenseCost?: string | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    pilotName?: string | null;
    pilotPhone?: string | null;
    slotDuration?: number | null;
    course?: {
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    latitude?: string | null;
    longitude?: string | null;
    staticMapAddress?: string | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCarListId?: string | null;
    carCourseId?: string | null;
  } | null;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  timeSlot?: string | null;
  isBooked?: boolean | null;
  courseEnd?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  schoolSlotAvailabilityId?: string | null;
  carSlotAvailabilityId?: string | null;
  studentSlotAvailabilityId?: string | null;
};

export type OnCreateCustomerRequestsSubscription = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateCustomerRequestsSubscription = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteCustomerRequestsSubscription = {
  __typename: "CustomerRequests";
  name?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  staticMapAddress?: string | null;
  phone?: string | null;
  email?: string | null;
  notes?: string | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateVideosSubscription = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateVideosSubscription = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteVideosSubscription = {
  __typename: "Videos";
  id: string;
  videoId: number;
  videoSequenceId: number;
  groupTagId: number;
  title?: string | null;
  desc?: string | null;
  url?: string | null;
  posterUrl?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateGroupVideoMapperSubscription = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateGroupVideoMapperSubscription = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteGroupVideoMapperSubscription = {
  __typename: "groupVideoMapper";
  id: string;
  groupTagId: number;
  tagName?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateAddressSubscription = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type OnUpdateAddressSubscription = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type OnDeleteAddressSubscription = {
  __typename: "Address";
  id: string;
  staticMapAddress: string;
  doorNumber?: string | null;
  area: string;
  locality?: string | null;
  landmark: string;
  label: string;
  pincode: number;
  student?: {
    __typename: "Student";
    id: string;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    gender?: string | null;
    email?: string | null;
    phone: string;
    dob?: string | null;
    city?: string | null;
    location?: string | null;
    profilePic?: string | null;
    addressList?: {
      __typename: "ModelAddressConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    course?: {
      __typename: "ModelCourseConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    schedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    scheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    slotAvailability?: {
      __typename: "ModelSlotAvailabilityConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    defaultAddressId?: string | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    studentCarId?: string | null;
  } | null;
  carSchedule?: {
    __typename: "ModelCarScheduleConnection";
    items: Array<{
      __typename: "CarSchedule";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      classNumber?: number | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleId?: string | null;
      carScheduleId?: string | null;
      studentScheduleId?: string | null;
      courseCarScheduleId?: string | null;
      addressCarScheduleId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  CarScheduleArchive?: {
    __typename: "ModelCarScheduleArchiveConnection";
    items: Array<{
      __typename: "CarScheduleArchive";
      date?: string | null;
      start?: string | null;
      end?: string | null;
      slotStatus?: number | null;
      kmsDriven?: string | null;
      pickedTime?: string | null;
      drivingStartedTime?: string | null;
      drivingCompletedTime?: string | null;
      droppedTime?: string | null;
      courseStatus?: number | null;
      rating?: string | null;
      id: string;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolScheduleArchiveId?: string | null;
      carScheduleArchiveId?: string | null;
      studentScheduleArchiveId?: string | null;
      courseCarScheduleArchiveId?: string | null;
      addressCarScheduleArchiveId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  course?: {
    __typename: "Course";
    id: string;
    courseName?: string | null;
    timeSlot?: string | null;
    status?: string | null;
    rating?: string | null;
    ratingCount?: number | null;
    arrivingBy?: string | null;
    lesson?: string | null;
    courseDay?: number | null;
    courseStart?: string | null;
    courseEnd?: string | null;
    videos?: Array<string | null> | null;
    car?: {
      __typename: "Car";
      id: string;
      carName?: string | null;
      carModel?: string | null;
      yearOfManf?: number | null;
      carNumber?: string | null;
      carPassword?: string | null;
      carImg?: Array<string | null> | null;
      rcCardImg?: string | null;
      carType?: string | null;
      dayStart?: string | null;
      dayEnd?: string | null;
      seatsOffered?: number | null;
      kms?: number | null;
      hoursPerDay?: number | null;
      totalDays?: number | null;
      validityDays?: number | null;
      forwardDays?: number | null;
      reverseDays?: number | null;
      classroomDays?: number | null;
      simulatorDays?: number | null;
      pricePrivate?: string | null;
      priceShared?: string | null;
      licenseCost?: string | null;
      pilotName?: string | null;
      pilotPhone?: string | null;
      slotDuration?: number | null;
      latitude?: string | null;
      longitude?: string | null;
      staticMapAddress?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCarListId?: string | null;
      carCourseId?: string | null;
    } | null;
    student?: {
      __typename: "Student";
      id: string;
      firstName?: string | null;
      middleName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      email?: string | null;
      phone: string;
      dob?: string | null;
      city?: string | null;
      location?: string | null;
      profilePic?: string | null;
      defaultAddressId?: string | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentCarId?: string | null;
    } | null;
    school?: {
      __typename: "School";
      id: string;
      name: string;
      contactPerson: string;
      email?: string | null;
      phone: string;
      pickupDuration?: number | null;
      rating?: string | null;
      ratingCount?: number | null;
      distance?: string | null;
      distanceFee?: string | null;
      kms?: string | null;
      days?: string | null;
      price?: string | null;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      landmark: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      locality?: string | null;
      establishedOn?: string | null;
      classroomTraining?: boolean | null;
      simulatorTraining?: boolean | null;
      schoolImg?: Array<string | null> | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    carSchedule?: {
      __typename: "ModelCarScheduleConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    payment?: {
      __typename: "Payment";
      id: string;
      amountReceived?: number | null;
      courseAmount?: number | null;
      status?: string | null;
      paymentDate?: string | null;
      tax?: number | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
    } | null;
    CarScheduleArchive?: {
      __typename: "ModelCarScheduleArchiveConnection";
      nextToken?: string | null;
      startedAt?: number | null;
    } | null;
    address?: {
      __typename: "Address";
      id: string;
      staticMapAddress: string;
      doorNumber?: string | null;
      area: string;
      locality?: string | null;
      landmark: string;
      label: string;
      pincode: number;
      latitude?: string | null;
      longitude?: string | null;
      isDefault?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      studentAddressListId?: string | null;
      addressCourseId?: string | null;
    } | null;
    totalClasses?: number | null;
    totalTime?: number | null;
    completedTime?: number | null;
    totalDistance?: number | null;
    completedClasses?: number | null;
    cancelledClasses?: number | null;
    missedClasses?: number | null;
    missedLimit?: number | null;
    cancelledLimit?: number | null;
    completedDistance?: string | null;
    isOfflineBooking?: boolean | null;
    courseCompleted?: boolean | null;
    courseSuspended?: boolean | null;
    createdAt: string;
    updatedAt: string;
    _version: number;
    _deleted?: boolean | null;
    _lastChangedAt: number;
    schoolCourseId?: string | null;
    studentCourseId?: string | null;
    paymentCourseId?: string | null;
    courseCarId?: string | null;
    courseAddressId?: string | null;
  } | null;
  latitude?: string | null;
  longitude?: string | null;
  isDefault?: boolean | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  studentAddressListId?: string | null;
  addressCourseId?: string | null;
};

export type OnCreatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeletePaymentSubscription = {
  __typename: "Payment";
  id: string;
  course?: {
    __typename: "ModelCourseConnection";
    items: Array<{
      __typename: "Course";
      id: string;
      courseName?: string | null;
      timeSlot?: string | null;
      status?: string | null;
      rating?: string | null;
      ratingCount?: number | null;
      arrivingBy?: string | null;
      lesson?: string | null;
      courseDay?: number | null;
      courseStart?: string | null;
      courseEnd?: string | null;
      videos?: Array<string | null> | null;
      totalClasses?: number | null;
      totalTime?: number | null;
      completedTime?: number | null;
      totalDistance?: number | null;
      completedClasses?: number | null;
      cancelledClasses?: number | null;
      missedClasses?: number | null;
      missedLimit?: number | null;
      cancelledLimit?: number | null;
      completedDistance?: string | null;
      isOfflineBooking?: boolean | null;
      courseCompleted?: boolean | null;
      courseSuspended?: boolean | null;
      createdAt: string;
      updatedAt: string;
      _version: number;
      _deleted?: boolean | null;
      _lastChangedAt: number;
      schoolCourseId?: string | null;
      studentCourseId?: string | null;
      paymentCourseId?: string | null;
      courseCarId?: string | null;
      courseAddressId?: string | null;
    } | null>;
    nextToken?: string | null;
    startedAt?: number | null;
  } | null;
  amountReceived?: number | null;
  courseAmount?: number | null;
  status?: string | null;
  paymentDate?: string | null;
  tax?: number | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnCreateErrorLogsSubscription = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnUpdateErrorLogsSubscription = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

export type OnDeleteErrorLogsSubscription = {
  __typename: "ErrorLogs";
  id: string;
  errorMessage?: string | null;
  timeStamp?: string | null;
  loginUserData?: string | null;
  createdAt: string;
  updatedAt: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateSchool(
    input: CreateSchoolInput,
    condition?: ModelSchoolConditionInput
  ): Promise<CreateSchoolMutation> {
    const statement = `mutation CreateSchool($input: CreateSchoolInput!, $condition: ModelSchoolConditionInput) {
        createSchool(input: $input, condition: $condition) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSchoolMutation>response.data.createSchool;
  }
  async UpdateSchool(
    input: UpdateSchoolInput,
    condition?: ModelSchoolConditionInput
  ): Promise<UpdateSchoolMutation> {
    const statement = `mutation UpdateSchool($input: UpdateSchoolInput!, $condition: ModelSchoolConditionInput) {
        updateSchool(input: $input, condition: $condition) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSchoolMutation>response.data.updateSchool;
  }
  async DeleteSchool(
    input: DeleteSchoolInput,
    condition?: ModelSchoolConditionInput
  ): Promise<DeleteSchoolMutation> {
    const statement = `mutation DeleteSchool($input: DeleteSchoolInput!, $condition: ModelSchoolConditionInput) {
        deleteSchool(input: $input, condition: $condition) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSchoolMutation>response.data.deleteSchool;
  }
  async CreateCar(
    input: CreateCarInput,
    condition?: ModelCarConditionInput
  ): Promise<CreateCarMutation> {
    const statement = `mutation CreateCar($input: CreateCarInput!, $condition: ModelCarConditionInput) {
        createCar(input: $input, condition: $condition) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCarMutation>response.data.createCar;
  }
  async UpdateCar(
    input: UpdateCarInput,
    condition?: ModelCarConditionInput
  ): Promise<UpdateCarMutation> {
    const statement = `mutation UpdateCar($input: UpdateCarInput!, $condition: ModelCarConditionInput) {
        updateCar(input: $input, condition: $condition) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCarMutation>response.data.updateCar;
  }
  async DeleteCar(
    input: DeleteCarInput,
    condition?: ModelCarConditionInput
  ): Promise<DeleteCarMutation> {
    const statement = `mutation DeleteCar($input: DeleteCarInput!, $condition: ModelCarConditionInput) {
        deleteCar(input: $input, condition: $condition) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCarMutation>response.data.deleteCar;
  }
  async CreateCarSchedule(
    input: CreateCarScheduleInput,
    condition?: ModelCarScheduleConditionInput
  ): Promise<CreateCarScheduleMutation> {
    const statement = `mutation CreateCarSchedule($input: CreateCarScheduleInput!, $condition: ModelCarScheduleConditionInput) {
        createCarSchedule(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCarScheduleMutation>response.data.createCarSchedule;
  }
  async UpdateCarSchedule(
    input: UpdateCarScheduleInput,
    condition?: ModelCarScheduleConditionInput
  ): Promise<UpdateCarScheduleMutation> {
    const statement = `mutation UpdateCarSchedule($input: UpdateCarScheduleInput!, $condition: ModelCarScheduleConditionInput) {
        updateCarSchedule(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCarScheduleMutation>response.data.updateCarSchedule;
  }
  async DeleteCarSchedule(
    input: DeleteCarScheduleInput,
    condition?: ModelCarScheduleConditionInput
  ): Promise<DeleteCarScheduleMutation> {
    const statement = `mutation DeleteCarSchedule($input: DeleteCarScheduleInput!, $condition: ModelCarScheduleConditionInput) {
        deleteCarSchedule(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCarScheduleMutation>response.data.deleteCarSchedule;
  }
  async CreateCarScheduleArchive(
    input: CreateCarScheduleArchiveInput,
    condition?: ModelCarScheduleArchiveConditionInput
  ): Promise<CreateCarScheduleArchiveMutation> {
    const statement = `mutation CreateCarScheduleArchive($input: CreateCarScheduleArchiveInput!, $condition: ModelCarScheduleArchiveConditionInput) {
        createCarScheduleArchive(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCarScheduleArchiveMutation>(
      response.data.createCarScheduleArchive
    );
  }
  async UpdateCarScheduleArchive(
    input: UpdateCarScheduleArchiveInput,
    condition?: ModelCarScheduleArchiveConditionInput
  ): Promise<UpdateCarScheduleArchiveMutation> {
    const statement = `mutation UpdateCarScheduleArchive($input: UpdateCarScheduleArchiveInput!, $condition: ModelCarScheduleArchiveConditionInput) {
        updateCarScheduleArchive(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCarScheduleArchiveMutation>(
      response.data.updateCarScheduleArchive
    );
  }
  async DeleteCarScheduleArchive(
    input: DeleteCarScheduleArchiveInput,
    condition?: ModelCarScheduleArchiveConditionInput
  ): Promise<DeleteCarScheduleArchiveMutation> {
    const statement = `mutation DeleteCarScheduleArchive($input: DeleteCarScheduleArchiveInput!, $condition: ModelCarScheduleArchiveConditionInput) {
        deleteCarScheduleArchive(input: $input, condition: $condition) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCarScheduleArchiveMutation>(
      response.data.deleteCarScheduleArchive
    );
  }
  async CreateStudent(
    input: CreateStudentInput,
    condition?: ModelStudentConditionInput
  ): Promise<CreateStudentMutation> {
    const statement = `mutation CreateStudent($input: CreateStudentInput!, $condition: ModelStudentConditionInput) {
        createStudent(input: $input, condition: $condition) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateStudentMutation>response.data.createStudent;
  }
  async UpdateStudent(
    input: UpdateStudentInput,
    condition?: ModelStudentConditionInput
  ): Promise<UpdateStudentMutation> {
    const statement = `mutation UpdateStudent($input: UpdateStudentInput!, $condition: ModelStudentConditionInput) {
        updateStudent(input: $input, condition: $condition) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateStudentMutation>response.data.updateStudent;
  }
  async DeleteStudent(
    input: DeleteStudentInput,
    condition?: ModelStudentConditionInput
  ): Promise<DeleteStudentMutation> {
    const statement = `mutation DeleteStudent($input: DeleteStudentInput!, $condition: ModelStudentConditionInput) {
        deleteStudent(input: $input, condition: $condition) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteStudentMutation>response.data.deleteStudent;
  }
  async CreateCourse(
    input: CreateCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<CreateCourseMutation> {
    const statement = `mutation CreateCourse($input: CreateCourseInput!, $condition: ModelCourseConditionInput) {
        createCourse(input: $input, condition: $condition) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCourseMutation>response.data.createCourse;
  }
  async UpdateCourse(
    input: UpdateCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<UpdateCourseMutation> {
    const statement = `mutation UpdateCourse($input: UpdateCourseInput!, $condition: ModelCourseConditionInput) {
        updateCourse(input: $input, condition: $condition) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCourseMutation>response.data.updateCourse;
  }
  async DeleteCourse(
    input: DeleteCourseInput,
    condition?: ModelCourseConditionInput
  ): Promise<DeleteCourseMutation> {
    const statement = `mutation DeleteCourse($input: DeleteCourseInput!, $condition: ModelCourseConditionInput) {
        deleteCourse(input: $input, condition: $condition) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCourseMutation>response.data.deleteCourse;
  }
  async CreateSlotAvailability(
    input: CreateSlotAvailabilityInput,
    condition?: ModelSlotAvailabilityConditionInput
  ): Promise<CreateSlotAvailabilityMutation> {
    const statement = `mutation CreateSlotAvailability($input: CreateSlotAvailabilityInput!, $condition: ModelSlotAvailabilityConditionInput) {
        createSlotAvailability(input: $input, condition: $condition) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSlotAvailabilityMutation>response.data.createSlotAvailability;
  }
  async UpdateSlotAvailability(
    input: UpdateSlotAvailabilityInput,
    condition?: ModelSlotAvailabilityConditionInput
  ): Promise<UpdateSlotAvailabilityMutation> {
    const statement = `mutation UpdateSlotAvailability($input: UpdateSlotAvailabilityInput!, $condition: ModelSlotAvailabilityConditionInput) {
        updateSlotAvailability(input: $input, condition: $condition) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSlotAvailabilityMutation>response.data.updateSlotAvailability;
  }
  async DeleteSlotAvailability(
    input: DeleteSlotAvailabilityInput,
    condition?: ModelSlotAvailabilityConditionInput
  ): Promise<DeleteSlotAvailabilityMutation> {
    const statement = `mutation DeleteSlotAvailability($input: DeleteSlotAvailabilityInput!, $condition: ModelSlotAvailabilityConditionInput) {
        deleteSlotAvailability(input: $input, condition: $condition) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSlotAvailabilityMutation>response.data.deleteSlotAvailability;
  }
  async CreateCustomerRequests(
    input: CreateCustomerRequestsInput,
    condition?: ModelCustomerRequestsConditionInput
  ): Promise<CreateCustomerRequestsMutation> {
    const statement = `mutation CreateCustomerRequests($input: CreateCustomerRequestsInput!, $condition: ModelCustomerRequestsConditionInput) {
        createCustomerRequests(input: $input, condition: $condition) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerRequestsMutation>response.data.createCustomerRequests;
  }
  async UpdateCustomerRequests(
    input: UpdateCustomerRequestsInput,
    condition?: ModelCustomerRequestsConditionInput
  ): Promise<UpdateCustomerRequestsMutation> {
    const statement = `mutation UpdateCustomerRequests($input: UpdateCustomerRequestsInput!, $condition: ModelCustomerRequestsConditionInput) {
        updateCustomerRequests(input: $input, condition: $condition) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerRequestsMutation>response.data.updateCustomerRequests;
  }
  async DeleteCustomerRequests(
    input: DeleteCustomerRequestsInput,
    condition?: ModelCustomerRequestsConditionInput
  ): Promise<DeleteCustomerRequestsMutation> {
    const statement = `mutation DeleteCustomerRequests($input: DeleteCustomerRequestsInput!, $condition: ModelCustomerRequestsConditionInput) {
        deleteCustomerRequests(input: $input, condition: $condition) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCustomerRequestsMutation>response.data.deleteCustomerRequests;
  }
  async CreateVideos(
    input: CreateVideosInput,
    condition?: ModelVideosConditionInput
  ): Promise<CreateVideosMutation> {
    const statement = `mutation CreateVideos($input: CreateVideosInput!, $condition: ModelVideosConditionInput) {
        createVideos(input: $input, condition: $condition) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateVideosMutation>response.data.createVideos;
  }
  async UpdateVideos(
    input: UpdateVideosInput,
    condition?: ModelVideosConditionInput
  ): Promise<UpdateVideosMutation> {
    const statement = `mutation UpdateVideos($input: UpdateVideosInput!, $condition: ModelVideosConditionInput) {
        updateVideos(input: $input, condition: $condition) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateVideosMutation>response.data.updateVideos;
  }
  async DeleteVideos(
    input: DeleteVideosInput,
    condition?: ModelVideosConditionInput
  ): Promise<DeleteVideosMutation> {
    const statement = `mutation DeleteVideos($input: DeleteVideosInput!, $condition: ModelVideosConditionInput) {
        deleteVideos(input: $input, condition: $condition) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteVideosMutation>response.data.deleteVideos;
  }
  async CreateGroupVideoMapper(
    input: CreateGroupVideoMapperInput,
    condition?: ModelGroupVideoMapperConditionInput
  ): Promise<CreateGroupVideoMapperMutation> {
    const statement = `mutation CreateGroupVideoMapper($input: CreateGroupVideoMapperInput!, $condition: ModelGroupVideoMapperConditionInput) {
        createGroupVideoMapper(input: $input, condition: $condition) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateGroupVideoMapperMutation>response.data.createGroupVideoMapper;
  }
  async UpdateGroupVideoMapper(
    input: UpdateGroupVideoMapperInput,
    condition?: ModelGroupVideoMapperConditionInput
  ): Promise<UpdateGroupVideoMapperMutation> {
    const statement = `mutation UpdateGroupVideoMapper($input: UpdateGroupVideoMapperInput!, $condition: ModelGroupVideoMapperConditionInput) {
        updateGroupVideoMapper(input: $input, condition: $condition) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateGroupVideoMapperMutation>response.data.updateGroupVideoMapper;
  }
  async DeleteGroupVideoMapper(
    input: DeleteGroupVideoMapperInput,
    condition?: ModelGroupVideoMapperConditionInput
  ): Promise<DeleteGroupVideoMapperMutation> {
    const statement = `mutation DeleteGroupVideoMapper($input: DeleteGroupVideoMapperInput!, $condition: ModelGroupVideoMapperConditionInput) {
        deleteGroupVideoMapper(input: $input, condition: $condition) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteGroupVideoMapperMutation>response.data.deleteGroupVideoMapper;
  }
  async CreateAddress(
    input: CreateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<CreateAddressMutation> {
    const statement = `mutation CreateAddress($input: CreateAddressInput!, $condition: ModelAddressConditionInput) {
        createAddress(input: $input, condition: $condition) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAddressMutation>response.data.createAddress;
  }
  async UpdateAddress(
    input: UpdateAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<UpdateAddressMutation> {
    const statement = `mutation UpdateAddress($input: UpdateAddressInput!, $condition: ModelAddressConditionInput) {
        updateAddress(input: $input, condition: $condition) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAddressMutation>response.data.updateAddress;
  }
  async DeleteAddress(
    input: DeleteAddressInput,
    condition?: ModelAddressConditionInput
  ): Promise<DeleteAddressMutation> {
    const statement = `mutation DeleteAddress($input: DeleteAddressInput!, $condition: ModelAddressConditionInput) {
        deleteAddress(input: $input, condition: $condition) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAddressMutation>response.data.deleteAddress;
  }
  async CreatePayment(
    input: CreatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<CreatePaymentMutation> {
    const statement = `mutation CreatePayment($input: CreatePaymentInput!, $condition: ModelPaymentConditionInput) {
        createPayment(input: $input, condition: $condition) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentMutation>response.data.createPayment;
  }
  async UpdatePayment(
    input: UpdatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<UpdatePaymentMutation> {
    const statement = `mutation UpdatePayment($input: UpdatePaymentInput!, $condition: ModelPaymentConditionInput) {
        updatePayment(input: $input, condition: $condition) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentMutation>response.data.updatePayment;
  }
  async DeletePayment(
    input: DeletePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<DeletePaymentMutation> {
    const statement = `mutation DeletePayment($input: DeletePaymentInput!, $condition: ModelPaymentConditionInput) {
        deletePayment(input: $input, condition: $condition) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentMutation>response.data.deletePayment;
  }
  async CreateErrorLogs(
    input: CreateErrorLogsInput,
    condition?: ModelErrorLogsConditionInput
  ): Promise<CreateErrorLogsMutation> {
    const statement = `mutation CreateErrorLogs($input: CreateErrorLogsInput!, $condition: ModelErrorLogsConditionInput) {
        createErrorLogs(input: $input, condition: $condition) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateErrorLogsMutation>response.data.createErrorLogs;
  }
  async UpdateErrorLogs(
    input: UpdateErrorLogsInput,
    condition?: ModelErrorLogsConditionInput
  ): Promise<UpdateErrorLogsMutation> {
    const statement = `mutation UpdateErrorLogs($input: UpdateErrorLogsInput!, $condition: ModelErrorLogsConditionInput) {
        updateErrorLogs(input: $input, condition: $condition) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateErrorLogsMutation>response.data.updateErrorLogs;
  }
  async DeleteErrorLogs(
    input: DeleteErrorLogsInput,
    condition?: ModelErrorLogsConditionInput
  ): Promise<DeleteErrorLogsMutation> {
    const statement = `mutation DeleteErrorLogs($input: DeleteErrorLogsInput!, $condition: ModelErrorLogsConditionInput) {
        deleteErrorLogs(input: $input, condition: $condition) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteErrorLogsMutation>response.data.deleteErrorLogs;
  }
  async GetSchool(id: string): Promise<GetSchoolQuery> {
    const statement = `query GetSchool($id: ID!) {
        getSchool(id: $id) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSchoolQuery>response.data.getSchool;
  }
  async ListSchools(
    filter?: ModelSchoolFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSchoolsQuery> {
    const statement = `query ListSchools($filter: ModelSchoolFilterInput, $limit: Int, $nextToken: String) {
        listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSchoolsQuery>response.data.listSchools;
  }
  async SyncSchools(
    filter?: ModelSchoolFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSchoolsQuery> {
    const statement = `query SyncSchools($filter: ModelSchoolFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSchools(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSchoolsQuery>response.data.syncSchools;
  }
  async GetCar(id: string): Promise<GetCarQuery> {
    const statement = `query GetCar($id: ID!) {
        getCar(id: $id) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCarQuery>response.data.getCar;
  }
  async ListCars(
    filter?: ModelCarFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCarsQuery> {
    const statement = `query ListCars($filter: ModelCarFilterInput, $limit: Int, $nextToken: String) {
        listCars(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCarsQuery>response.data.listCars;
  }
  async SyncCars(
    filter?: ModelCarFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCarsQuery> {
    const statement = `query SyncCars($filter: ModelCarFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCars(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCarsQuery>response.data.syncCars;
  }
  async GetCarSchedule(id: string): Promise<GetCarScheduleQuery> {
    const statement = `query GetCarSchedule($id: ID!) {
        getCarSchedule(id: $id) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCarScheduleQuery>response.data.getCarSchedule;
  }
  async ListCarSchedules(
    filter?: ModelCarScheduleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCarSchedulesQuery> {
    const statement = `query ListCarSchedules($filter: ModelCarScheduleFilterInput, $limit: Int, $nextToken: String) {
        listCarSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            date
            start
            end
            slotStatus
            kmsDriven
            pickedTime
            drivingStartedTime
            drivingCompletedTime
            droppedTime
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            courseStatus
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            rating
            classNumber
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolScheduleId
            carScheduleId
            studentScheduleId
            courseCarScheduleId
            addressCarScheduleId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCarSchedulesQuery>response.data.listCarSchedules;
  }
  async SyncCarSchedules(
    filter?: ModelCarScheduleFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCarSchedulesQuery> {
    const statement = `query SyncCarSchedules($filter: ModelCarScheduleFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCarSchedules(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            date
            start
            end
            slotStatus
            kmsDriven
            pickedTime
            drivingStartedTime
            drivingCompletedTime
            droppedTime
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            courseStatus
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            rating
            classNumber
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolScheduleId
            carScheduleId
            studentScheduleId
            courseCarScheduleId
            addressCarScheduleId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCarSchedulesQuery>response.data.syncCarSchedules;
  }
  async GetCarScheduleArchive(id: string): Promise<GetCarScheduleArchiveQuery> {
    const statement = `query GetCarScheduleArchive($id: ID!) {
        getCarScheduleArchive(id: $id) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCarScheduleArchiveQuery>response.data.getCarScheduleArchive;
  }
  async ListCarScheduleArchives(
    filter?: ModelCarScheduleArchiveFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCarScheduleArchivesQuery> {
    const statement = `query ListCarScheduleArchives($filter: ModelCarScheduleArchiveFilterInput, $limit: Int, $nextToken: String) {
        listCarScheduleArchives(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            date
            start
            end
            slotStatus
            kmsDriven
            pickedTime
            drivingStartedTime
            drivingCompletedTime
            droppedTime
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            courseStatus
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            rating
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolScheduleArchiveId
            carScheduleArchiveId
            studentScheduleArchiveId
            courseCarScheduleArchiveId
            addressCarScheduleArchiveId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCarScheduleArchivesQuery>response.data.listCarScheduleArchives;
  }
  async SyncCarScheduleArchives(
    filter?: ModelCarScheduleArchiveFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCarScheduleArchivesQuery> {
    const statement = `query SyncCarScheduleArchives($filter: ModelCarScheduleArchiveFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCarScheduleArchives(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            date
            start
            end
            slotStatus
            kmsDriven
            pickedTime
            drivingStartedTime
            drivingCompletedTime
            droppedTime
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            courseStatus
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            rating
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolScheduleArchiveId
            carScheduleArchiveId
            studentScheduleArchiveId
            courseCarScheduleArchiveId
            addressCarScheduleArchiveId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCarScheduleArchivesQuery>response.data.syncCarScheduleArchives;
  }
  async GetStudent(id: string): Promise<GetStudentQuery> {
    const statement = `query GetStudent($id: ID!) {
        getStudent(id: $id) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStudentQuery>response.data.getStudent;
  }
  async ListStudents(
    filter?: ModelStudentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListStudentsQuery> {
    const statement = `query ListStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String) {
        listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListStudentsQuery>response.data.listStudents;
  }
  async SyncStudents(
    filter?: ModelStudentFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncStudentsQuery> {
    const statement = `query SyncStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncStudents(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncStudentsQuery>response.data.syncStudents;
  }
  async GetCourse(id: string): Promise<GetCourseQuery> {
    const statement = `query GetCourse($id: ID!) {
        getCourse(id: $id) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCourseQuery>response.data.getCourse;
  }
  async ListCourses(
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCoursesQuery> {
    const statement = `query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
        listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCoursesQuery>response.data.listCourses;
  }
  async SyncCourses(
    filter?: ModelCourseFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCoursesQuery> {
    const statement = `query SyncCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCourses(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCoursesQuery>response.data.syncCourses;
  }
  async GetSlotAvailability(id: string): Promise<GetSlotAvailabilityQuery> {
    const statement = `query GetSlotAvailability($id: ID!) {
        getSlotAvailability(id: $id) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSlotAvailabilityQuery>response.data.getSlotAvailability;
  }
  async ListSlotAvailabilities(
    filter?: ModelSlotAvailabilityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSlotAvailabilitiesQuery> {
    const statement = `query ListSlotAvailabilities($filter: ModelSlotAvailabilityFilterInput, $limit: Int, $nextToken: String) {
        listSlotAvailabilities(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            timeSlot
            isBooked
            courseEnd
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolSlotAvailabilityId
            carSlotAvailabilityId
            studentSlotAvailabilityId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSlotAvailabilitiesQuery>response.data.listSlotAvailabilities;
  }
  async SyncSlotAvailabilities(
    filter?: ModelSlotAvailabilityFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncSlotAvailabilitiesQuery> {
    const statement = `query SyncSlotAvailabilities($filter: ModelSlotAvailabilityFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncSlotAvailabilities(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            timeSlot
            isBooked
            courseEnd
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolSlotAvailabilityId
            carSlotAvailabilityId
            studentSlotAvailabilityId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncSlotAvailabilitiesQuery>response.data.syncSlotAvailabilities;
  }
  async GetCustomerRequests(id: string): Promise<GetCustomerRequestsQuery> {
    const statement = `query GetCustomerRequests($id: ID!) {
        getCustomerRequests(id: $id) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerRequestsQuery>response.data.getCustomerRequests;
  }
  async ListCustomerRequests(
    filter?: ModelCustomerRequestsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerRequestsQuery> {
    const statement = `query ListCustomerRequests($filter: ModelCustomerRequestsFilterInput, $limit: Int, $nextToken: String) {
        listCustomerRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            name
            latitude
            longitude
            staticMapAddress
            phone
            email
            notes
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerRequestsQuery>response.data.listCustomerRequests;
  }
  async SyncCustomerRequests(
    filter?: ModelCustomerRequestsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncCustomerRequestsQuery> {
    const statement = `query SyncCustomerRequests($filter: ModelCustomerRequestsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncCustomerRequests(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            name
            latitude
            longitude
            staticMapAddress
            phone
            email
            notes
            id
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncCustomerRequestsQuery>response.data.syncCustomerRequests;
  }
  async GetVideos(videoId: number): Promise<GetVideosQuery> {
    const statement = `query GetVideos($videoId: Int!) {
        getVideos(videoId: $videoId) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      videoId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetVideosQuery>response.data.getVideos;
  }
  async ListVideos(
    videoId?: number,
    filter?: ModelVideosFilterInput,
    limit?: number,
    nextToken?: string,
    sortDirection?: ModelSortDirection
  ): Promise<ListVideosQuery> {
    const statement = `query ListVideos($videoId: Int, $filter: ModelVideosFilterInput, $limit: Int, $nextToken: String, $sortDirection: ModelSortDirection) {
        listVideos(
          videoId: $videoId
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          sortDirection: $sortDirection
        ) {
          __typename
          items {
            __typename
            id
            videoId
            videoSequenceId
            groupTagId
            title
            desc
            url
            posterUrl
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (videoId) {
      gqlAPIServiceArguments.videoId = videoId;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListVideosQuery>response.data.listVideos;
  }
  async SyncVideos(
    filter?: ModelVideosFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncVideosQuery> {
    const statement = `query SyncVideos($filter: ModelVideosFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncVideos(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            videoId
            videoSequenceId
            groupTagId
            title
            desc
            url
            posterUrl
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncVideosQuery>response.data.syncVideos;
  }
  async GetGroupVideoMapper(id: string): Promise<GetGroupVideoMapperQuery> {
    const statement = `query GetGroupVideoMapper($id: ID!) {
        getGroupVideoMapper(id: $id) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetGroupVideoMapperQuery>response.data.getGroupVideoMapper;
  }
  async ListGroupVideoMappers(
    filter?: ModelGroupVideoMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListGroupVideoMappersQuery> {
    const statement = `query ListGroupVideoMappers($filter: ModelGroupVideoMapperFilterInput, $limit: Int, $nextToken: String) {
        listGroupVideoMappers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            groupTagId
            tagName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListGroupVideoMappersQuery>response.data.listGroupVideoMappers;
  }
  async SyncGroupVideoMappers(
    filter?: ModelGroupVideoMapperFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncGroupVideoMappersQuery> {
    const statement = `query SyncGroupVideoMappers($filter: ModelGroupVideoMapperFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncGroupVideoMappers(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            groupTagId
            tagName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncGroupVideoMappersQuery>response.data.syncGroupVideoMappers;
  }
  async GetAddress(id: string): Promise<GetAddressQuery> {
    const statement = `query GetAddress($id: ID!) {
        getAddress(id: $id) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAddressQuery>response.data.getAddress;
  }
  async ListAddresses(
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAddressesQuery> {
    const statement = `query ListAddresses($filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
        listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAddressesQuery>response.data.listAddresses;
  }
  async SyncAddresses(
    filter?: ModelAddressFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncAddressesQuery> {
    const statement = `query SyncAddresses($filter: ModelAddressFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncAddresses(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncAddressesQuery>response.data.syncAddresses;
  }
  async GetPayment(id: string): Promise<GetPaymentQuery> {
    const statement = `query GetPayment($id: ID!) {
        getPayment(id: $id) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentQuery>response.data.getPayment;
  }
  async ListPayments(
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPaymentsQuery> {
    const statement = `query ListPayments($filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
        listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentsQuery>response.data.listPayments;
  }
  async SyncPayments(
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncPaymentsQuery> {
    const statement = `query SyncPayments($filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncPayments(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncPaymentsQuery>response.data.syncPayments;
  }
  async GetErrorLogs(id: string): Promise<GetErrorLogsQuery> {
    const statement = `query GetErrorLogs($id: ID!) {
        getErrorLogs(id: $id) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetErrorLogsQuery>response.data.getErrorLogs;
  }
  async ListErrorLogs(
    filter?: ModelErrorLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListErrorLogsQuery> {
    const statement = `query ListErrorLogs($filter: ModelErrorLogsFilterInput, $limit: Int, $nextToken: String) {
        listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            errorMessage
            timeStamp
            loginUserData
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListErrorLogsQuery>response.data.listErrorLogs;
  }
  async SyncErrorLogs(
    filter?: ModelErrorLogsFilterInput,
    limit?: number,
    nextToken?: string,
    lastSync?: number
  ): Promise<SyncErrorLogsQuery> {
    const statement = `query SyncErrorLogs($filter: ModelErrorLogsFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
        syncErrorLogs(
          filter: $filter
          limit: $limit
          nextToken: $nextToken
          lastSync: $lastSync
        ) {
          __typename
          items {
            __typename
            id
            errorMessage
            timeStamp
            loginUserData
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (lastSync) {
      gqlAPIServiceArguments.lastSync = lastSync;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SyncErrorLogsQuery>response.data.syncErrorLogs;
  }
  OnCreateSchoolListener(
    filter?: ModelSubscriptionSchoolFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSchool">>
  > {
    const statement = `subscription OnCreateSchool($filter: ModelSubscriptionSchoolFilterInput) {
        onCreateSchool(filter: $filter) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateSchool">>
    >;
  }

  OnUpdateSchoolListener(
    filter?: ModelSubscriptionSchoolFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSchool">>
  > {
    const statement = `subscription OnUpdateSchool($filter: ModelSubscriptionSchoolFilterInput) {
        onUpdateSchool(filter: $filter) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateSchool">>
    >;
  }

  OnDeleteSchoolListener(
    filter?: ModelSubscriptionSchoolFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSchool">>
  > {
    const statement = `subscription OnDeleteSchool($filter: ModelSubscriptionSchoolFilterInput) {
        onDeleteSchool(filter: $filter) {
          __typename
          id
          name
          contactPerson
          email
          phone
          pickupDuration
          rating
          ratingCount
          distance
          distanceFee
          kms
          days
          price
          carList {
            __typename
            items {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            nextToken
            startedAt
          }
          staticMapAddress
          doorNumber
          area
          landmark
          pincode
          latitude
          longitude
          locality
          establishedOn
          classroomTraining
          simulatorTraining
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schoolImg
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteSchool">>
    >;
  }

  OnCreateCarListener(
    filter?: ModelSubscriptionCarFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCar">>
  > {
    const statement = `subscription OnCreateCar($filter: ModelSubscriptionCarFilterInput) {
        onCreateCar(filter: $filter) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCar">>
    >;
  }

  OnUpdateCarListener(
    filter?: ModelSubscriptionCarFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCar">>
  > {
    const statement = `subscription OnUpdateCar($filter: ModelSubscriptionCarFilterInput) {
        onUpdateCar(filter: $filter) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCar">>
    >;
  }

  OnDeleteCarListener(
    filter?: ModelSubscriptionCarFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCar">>
  > {
    const statement = `subscription OnDeleteCar($filter: ModelSubscriptionCarFilterInput) {
        onDeleteCar(filter: $filter) {
          __typename
          id
          carName
          carModel
          yearOfManf
          carNumber
          carPassword
          carImg
          rcCardImg
          carType
          dayStart
          dayEnd
          seatsOffered
          kms
          hoursPerDay
          totalDays
          validityDays
          forwardDays
          reverseDays
          classroomDays
          simulatorDays
          pricePrivate
          priceShared
          licenseCost
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          pilotName
          pilotPhone
          slotDuration
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          latitude
          longitude
          staticMapAddress
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCarListId
          carCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCar">>
    >;
  }

  OnCreateCarScheduleListener(
    filter?: ModelSubscriptionCarScheduleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCarSchedule">>
  > {
    const statement = `subscription OnCreateCarSchedule($filter: ModelSubscriptionCarScheduleFilterInput) {
        onCreateCarSchedule(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCarSchedule">>
    >;
  }

  OnUpdateCarScheduleListener(
    filter?: ModelSubscriptionCarScheduleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCarSchedule">>
  > {
    const statement = `subscription OnUpdateCarSchedule($filter: ModelSubscriptionCarScheduleFilterInput) {
        onUpdateCarSchedule(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCarSchedule">>
    >;
  }

  OnDeleteCarScheduleListener(
    filter?: ModelSubscriptionCarScheduleFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCarSchedule">>
  > {
    const statement = `subscription OnDeleteCarSchedule($filter: ModelSubscriptionCarScheduleFilterInput) {
        onDeleteCarSchedule(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          classNumber
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleId
          carScheduleId
          studentScheduleId
          courseCarScheduleId
          addressCarScheduleId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCarSchedule">>
    >;
  }

  OnCreateCarScheduleArchiveListener(
    filter?: ModelSubscriptionCarScheduleArchiveFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCarScheduleArchive">
    >
  > {
    const statement = `subscription OnCreateCarScheduleArchive($filter: ModelSubscriptionCarScheduleArchiveFilterInput) {
        onCreateCarScheduleArchive(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCarScheduleArchive">
      >
    >;
  }

  OnUpdateCarScheduleArchiveListener(
    filter?: ModelSubscriptionCarScheduleArchiveFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCarScheduleArchive">
    >
  > {
    const statement = `subscription OnUpdateCarScheduleArchive($filter: ModelSubscriptionCarScheduleArchiveFilterInput) {
        onUpdateCarScheduleArchive(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCarScheduleArchive">
      >
    >;
  }

  OnDeleteCarScheduleArchiveListener(
    filter?: ModelSubscriptionCarScheduleArchiveFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCarScheduleArchive">
    >
  > {
    const statement = `subscription OnDeleteCarScheduleArchive($filter: ModelSubscriptionCarScheduleArchiveFilterInput) {
        onDeleteCarScheduleArchive(filter: $filter) {
          __typename
          date
          start
          end
          slotStatus
          kmsDriven
          pickedTime
          drivingStartedTime
          drivingCompletedTime
          droppedTime
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          courseStatus
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          rating
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolScheduleArchiveId
          carScheduleArchiveId
          studentScheduleArchiveId
          courseCarScheduleArchiveId
          addressCarScheduleArchiveId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCarScheduleArchive">
      >
    >;
  }

  OnCreateStudentListener(
    filter?: ModelSubscriptionStudentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStudent">>
  > {
    const statement = `subscription OnCreateStudent($filter: ModelSubscriptionStudentFilterInput) {
        onCreateStudent(filter: $filter) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateStudent">>
    >;
  }

  OnUpdateStudentListener(
    filter?: ModelSubscriptionStudentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStudent">>
  > {
    const statement = `subscription OnUpdateStudent($filter: ModelSubscriptionStudentFilterInput) {
        onUpdateStudent(filter: $filter) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateStudent">>
    >;
  }

  OnDeleteStudentListener(
    filter?: ModelSubscriptionStudentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStudent">>
  > {
    const statement = `subscription OnDeleteStudent($filter: ModelSubscriptionStudentFilterInput) {
        onDeleteStudent(filter: $filter) {
          __typename
          id
          firstName
          middleName
          lastName
          gender
          email
          phone
          dob
          city
          location
          profilePic
          addressList {
            __typename
            items {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          schedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          scheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          slotAvailability {
            __typename
            items {
              __typename
              timeSlot
              isBooked
              courseEnd
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolSlotAvailabilityId
              carSlotAvailabilityId
              studentSlotAvailabilityId
            }
            nextToken
            startedAt
          }
          defaultAddressId
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentCarId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteStudent">>
    >;
  }

  OnCreateCourseListener(
    filter?: ModelSubscriptionCourseFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCourse">>
  > {
    const statement = `subscription OnCreateCourse($filter: ModelSubscriptionCourseFilterInput) {
        onCreateCourse(filter: $filter) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateCourse">>
    >;
  }

  OnUpdateCourseListener(
    filter?: ModelSubscriptionCourseFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCourse">>
  > {
    const statement = `subscription OnUpdateCourse($filter: ModelSubscriptionCourseFilterInput) {
        onUpdateCourse(filter: $filter) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateCourse">>
    >;
  }

  OnDeleteCourseListener(
    filter?: ModelSubscriptionCourseFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCourse">>
  > {
    const statement = `subscription OnDeleteCourse($filter: ModelSubscriptionCourseFilterInput) {
        onDeleteCourse(filter: $filter) {
          __typename
          id
          courseName
          timeSlot
          status
          rating
          ratingCount
          arrivingBy
          lesson
          courseDay
          courseStart
          courseEnd
          videos
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          payment {
            __typename
            id
            course {
              __typename
              nextToken
              startedAt
            }
            amountReceived
            courseAmount
            status
            paymentDate
            tax
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          address {
            __typename
            id
            staticMapAddress
            doorNumber
            area
            locality
            landmark
            label
            pincode
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            latitude
            longitude
            isDefault
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentAddressListId
            addressCourseId
          }
          totalClasses
          totalTime
          completedTime
          totalDistance
          completedClasses
          cancelledClasses
          missedClasses
          missedLimit
          cancelledLimit
          completedDistance
          isOfflineBooking
          courseCompleted
          courseSuspended
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolCourseId
          studentCourseId
          paymentCourseId
          courseCarId
          courseAddressId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteCourse">>
    >;
  }

  OnCreateSlotAvailabilityListener(
    filter?: ModelSubscriptionSlotAvailabilityFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateSlotAvailability">
    >
  > {
    const statement = `subscription OnCreateSlotAvailability($filter: ModelSubscriptionSlotAvailabilityFilterInput) {
        onCreateSlotAvailability(filter: $filter) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateSlotAvailability">
      >
    >;
  }

  OnUpdateSlotAvailabilityListener(
    filter?: ModelSubscriptionSlotAvailabilityFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateSlotAvailability">
    >
  > {
    const statement = `subscription OnUpdateSlotAvailability($filter: ModelSubscriptionSlotAvailabilityFilterInput) {
        onUpdateSlotAvailability(filter: $filter) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateSlotAvailability">
      >
    >;
  }

  OnDeleteSlotAvailabilityListener(
    filter?: ModelSubscriptionSlotAvailabilityFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteSlotAvailability">
    >
  > {
    const statement = `subscription OnDeleteSlotAvailability($filter: ModelSubscriptionSlotAvailabilityFilterInput) {
        onDeleteSlotAvailability(filter: $filter) {
          __typename
          school {
            __typename
            id
            name
            contactPerson
            email
            phone
            pickupDuration
            rating
            ratingCount
            distance
            distanceFee
            kms
            days
            price
            carList {
              __typename
              nextToken
              startedAt
            }
            staticMapAddress
            doorNumber
            area
            landmark
            pincode
            latitude
            longitude
            locality
            establishedOn
            classroomTraining
            simulatorTraining
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schoolImg
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          car {
            __typename
            id
            carName
            carModel
            yearOfManf
            carNumber
            carPassword
            carImg
            rcCardImg
            carType
            dayStart
            dayEnd
            seatsOffered
            kms
            hoursPerDay
            totalDays
            validityDays
            forwardDays
            reverseDays
            classroomDays
            simulatorDays
            pricePrivate
            priceShared
            licenseCost
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            pilotName
            pilotPhone
            slotDuration
            course {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            latitude
            longitude
            staticMapAddress
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCarListId
            carCourseId
          }
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          timeSlot
          isBooked
          courseEnd
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          schoolSlotAvailabilityId
          carSlotAvailabilityId
          studentSlotAvailabilityId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteSlotAvailability">
      >
    >;
  }

  OnCreateCustomerRequestsListener(
    filter?: ModelSubscriptionCustomerRequestsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateCustomerRequests">
    >
  > {
    const statement = `subscription OnCreateCustomerRequests($filter: ModelSubscriptionCustomerRequestsFilterInput) {
        onCreateCustomerRequests(filter: $filter) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateCustomerRequests">
      >
    >;
  }

  OnUpdateCustomerRequestsListener(
    filter?: ModelSubscriptionCustomerRequestsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateCustomerRequests">
    >
  > {
    const statement = `subscription OnUpdateCustomerRequests($filter: ModelSubscriptionCustomerRequestsFilterInput) {
        onUpdateCustomerRequests(filter: $filter) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateCustomerRequests">
      >
    >;
  }

  OnDeleteCustomerRequestsListener(
    filter?: ModelSubscriptionCustomerRequestsFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteCustomerRequests">
    >
  > {
    const statement = `subscription OnDeleteCustomerRequests($filter: ModelSubscriptionCustomerRequestsFilterInput) {
        onDeleteCustomerRequests(filter: $filter) {
          __typename
          name
          latitude
          longitude
          staticMapAddress
          phone
          email
          notes
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteCustomerRequests">
      >
    >;
  }

  OnCreateVideosListener(
    filter?: ModelSubscriptionVideosFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVideos">>
  > {
    const statement = `subscription OnCreateVideos($filter: ModelSubscriptionVideosFilterInput) {
        onCreateVideos(filter: $filter) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateVideos">>
    >;
  }

  OnUpdateVideosListener(
    filter?: ModelSubscriptionVideosFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVideos">>
  > {
    const statement = `subscription OnUpdateVideos($filter: ModelSubscriptionVideosFilterInput) {
        onUpdateVideos(filter: $filter) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateVideos">>
    >;
  }

  OnDeleteVideosListener(
    filter?: ModelSubscriptionVideosFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVideos">>
  > {
    const statement = `subscription OnDeleteVideos($filter: ModelSubscriptionVideosFilterInput) {
        onDeleteVideos(filter: $filter) {
          __typename
          id
          videoId
          videoSequenceId
          groupTagId
          title
          desc
          url
          posterUrl
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteVideos">>
    >;
  }

  OnCreateGroupVideoMapperListener(
    filter?: ModelSubscriptionGroupVideoMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateGroupVideoMapper">
    >
  > {
    const statement = `subscription OnCreateGroupVideoMapper($filter: ModelSubscriptionGroupVideoMapperFilterInput) {
        onCreateGroupVideoMapper(filter: $filter) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateGroupVideoMapper">
      >
    >;
  }

  OnUpdateGroupVideoMapperListener(
    filter?: ModelSubscriptionGroupVideoMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateGroupVideoMapper">
    >
  > {
    const statement = `subscription OnUpdateGroupVideoMapper($filter: ModelSubscriptionGroupVideoMapperFilterInput) {
        onUpdateGroupVideoMapper(filter: $filter) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateGroupVideoMapper">
      >
    >;
  }

  OnDeleteGroupVideoMapperListener(
    filter?: ModelSubscriptionGroupVideoMapperFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteGroupVideoMapper">
    >
  > {
    const statement = `subscription OnDeleteGroupVideoMapper($filter: ModelSubscriptionGroupVideoMapperFilterInput) {
        onDeleteGroupVideoMapper(filter: $filter) {
          __typename
          id
          groupTagId
          tagName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteGroupVideoMapper">
      >
    >;
  }

  OnCreateAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
  > {
    const statement = `subscription OnCreateAddress($filter: ModelSubscriptionAddressFilterInput) {
        onCreateAddress(filter: $filter) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAddress">>
    >;
  }

  OnUpdateAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
  > {
    const statement = `subscription OnUpdateAddress($filter: ModelSubscriptionAddressFilterInput) {
        onUpdateAddress(filter: $filter) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAddress">>
    >;
  }

  OnDeleteAddressListener(
    filter?: ModelSubscriptionAddressFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
  > {
    const statement = `subscription OnDeleteAddress($filter: ModelSubscriptionAddressFilterInput) {
        onDeleteAddress(filter: $filter) {
          __typename
          id
          staticMapAddress
          doorNumber
          area
          locality
          landmark
          label
          pincode
          student {
            __typename
            id
            firstName
            middleName
            lastName
            gender
            email
            phone
            dob
            city
            location
            profilePic
            addressList {
              __typename
              nextToken
              startedAt
            }
            course {
              __typename
              nextToken
              startedAt
            }
            schedule {
              __typename
              nextToken
              startedAt
            }
            scheduleArchive {
              __typename
              nextToken
              startedAt
            }
            slotAvailability {
              __typename
              nextToken
              startedAt
            }
            defaultAddressId
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            studentCarId
          }
          carSchedule {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              classNumber
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleId
              carScheduleId
              studentScheduleId
              courseCarScheduleId
              addressCarScheduleId
            }
            nextToken
            startedAt
          }
          CarScheduleArchive {
            __typename
            items {
              __typename
              date
              start
              end
              slotStatus
              kmsDriven
              pickedTime
              drivingStartedTime
              drivingCompletedTime
              droppedTime
              courseStatus
              rating
              id
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolScheduleArchiveId
              carScheduleArchiveId
              studentScheduleArchiveId
              courseCarScheduleArchiveId
              addressCarScheduleArchiveId
            }
            nextToken
            startedAt
          }
          course {
            __typename
            id
            courseName
            timeSlot
            status
            rating
            ratingCount
            arrivingBy
            lesson
            courseDay
            courseStart
            courseEnd
            videos
            car {
              __typename
              id
              carName
              carModel
              yearOfManf
              carNumber
              carPassword
              carImg
              rcCardImg
              carType
              dayStart
              dayEnd
              seatsOffered
              kms
              hoursPerDay
              totalDays
              validityDays
              forwardDays
              reverseDays
              classroomDays
              simulatorDays
              pricePrivate
              priceShared
              licenseCost
              pilotName
              pilotPhone
              slotDuration
              latitude
              longitude
              staticMapAddress
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCarListId
              carCourseId
            }
            student {
              __typename
              id
              firstName
              middleName
              lastName
              gender
              email
              phone
              dob
              city
              location
              profilePic
              defaultAddressId
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentCarId
            }
            school {
              __typename
              id
              name
              contactPerson
              email
              phone
              pickupDuration
              rating
              ratingCount
              distance
              distanceFee
              kms
              days
              price
              staticMapAddress
              doorNumber
              area
              landmark
              pincode
              latitude
              longitude
              locality
              establishedOn
              classroomTraining
              simulatorTraining
              schoolImg
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            carSchedule {
              __typename
              nextToken
              startedAt
            }
            payment {
              __typename
              id
              amountReceived
              courseAmount
              status
              paymentDate
              tax
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
            }
            CarScheduleArchive {
              __typename
              nextToken
              startedAt
            }
            address {
              __typename
              id
              staticMapAddress
              doorNumber
              area
              locality
              landmark
              label
              pincode
              latitude
              longitude
              isDefault
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              studentAddressListId
              addressCourseId
            }
            totalClasses
            totalTime
            completedTime
            totalDistance
            completedClasses
            cancelledClasses
            missedClasses
            missedLimit
            cancelledLimit
            completedDistance
            isOfflineBooking
            courseCompleted
            courseSuspended
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            schoolCourseId
            studentCourseId
            paymentCourseId
            courseCarId
            courseAddressId
          }
          latitude
          longitude
          isDefault
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          studentAddressListId
          addressCourseId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAddress">>
    >;
  }

  OnCreatePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePayment">>
  > {
    const statement = `subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onCreatePayment(filter: $filter) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePayment">>
    >;
  }

  OnUpdatePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePayment">>
  > {
    const statement = `subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onUpdatePayment(filter: $filter) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePayment">>
    >;
  }

  OnDeletePaymentListener(
    filter?: ModelSubscriptionPaymentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePayment">>
  > {
    const statement = `subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
        onDeletePayment(filter: $filter) {
          __typename
          id
          course {
            __typename
            items {
              __typename
              id
              courseName
              timeSlot
              status
              rating
              ratingCount
              arrivingBy
              lesson
              courseDay
              courseStart
              courseEnd
              videos
              totalClasses
              totalTime
              completedTime
              totalDistance
              completedClasses
              cancelledClasses
              missedClasses
              missedLimit
              cancelledLimit
              completedDistance
              isOfflineBooking
              courseCompleted
              courseSuspended
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              schoolCourseId
              studentCourseId
              paymentCourseId
              courseCarId
              courseAddressId
            }
            nextToken
            startedAt
          }
          amountReceived
          courseAmount
          status
          paymentDate
          tax
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePayment">>
    >;
  }

  OnCreateErrorLogsListener(
    filter?: ModelSubscriptionErrorLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateErrorLogs">>
  > {
    const statement = `subscription OnCreateErrorLogs($filter: ModelSubscriptionErrorLogsFilterInput) {
        onCreateErrorLogs(filter: $filter) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateErrorLogs">>
    >;
  }

  OnUpdateErrorLogsListener(
    filter?: ModelSubscriptionErrorLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateErrorLogs">>
  > {
    const statement = `subscription OnUpdateErrorLogs($filter: ModelSubscriptionErrorLogsFilterInput) {
        onUpdateErrorLogs(filter: $filter) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateErrorLogs">>
    >;
  }

  OnDeleteErrorLogsListener(
    filter?: ModelSubscriptionErrorLogsFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteErrorLogs">>
  > {
    const statement = `subscription OnDeleteErrorLogs($filter: ModelSubscriptionErrorLogsFilterInput) {
        onDeleteErrorLogs(filter: $filter) {
          __typename
          id
          errorMessage
          timeStamp
          loginUserData
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteErrorLogs">>
    >;
  }
}