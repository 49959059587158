import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APIService } from 'src/API.service';
import { AuthService } from './providers/auth.service';
import { DriveCaroService } from './providers/drive-caro.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Home', url: '/dashboard', icon: 'home' },
    { title: 'My Cars', url: '/cars', icon: 'car-sport' },
    { title: 'My Rides', url: '/my-rides', icon: 'speedometer' },
    { title: 'Payments', url: '/payments', icon: 'card' },
    { title: 'Reports', url: '/reports', icon: 'document' },
    // { title: 'Payments', url: '/my-rides', icon: 'speedometer' },
    { title: 'Car Locations', url: '/car-locations', icon: 'location' },
    { title: 'Profile', url: '/home', icon: 'school' },
    // { title: 'Schedule', url: '/schedule', icon: 'calendar' },
    // { title: 'Logout', url: '/pilots', icon: 'log-out' },
    // { title: 'Archived', url: '/home/Archived', icon: 'archive' },
    // { title: 'Trash', url: '/home/Trash', icon: 'trash' },
    // { title: 'Spam', url: '/home/Spam', icon: 'warning' },
  ];
  // public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(
    private apiService: APIService,
    private auth: AuthService,
    public driveCaroService: DriveCaroService,
    public router: Router,
  ) { }


  ngOnInit() {
    this.checkLoginStatus();
  }

  checkLoginStatus() {

    try {
      this.auth.getUserAttributes().then(user => {
        if (!(user.attributes && user.attributes.sub)) {
          return this.router.navigateByUrl('/login');
        }
        this.driveCaroService.schoolId = user.attributes.sub;
        this.driveCaroService.phone = user.attributes.phone_number;
        this.driveCaroService.webToken = user.signInUserSession.idToken.jwtToken;
        this.getSchoolData();
        // this.driveCaroService.getPilots();
      })
    } catch (error) {
      console.log(error);
    }
  }

  getSchoolData() {
    this.apiService.GetSchool(this.driveCaroService.schoolId).then((data) => {
      console.log('data', data);
      if (!data) {
        this.driveCaroService.createSchool(this.driveCaroService.phone, this.driveCaroService.schoolId)
      }
      // this.school = data;
      // this.driveCaroService.getCars();
      this.driveCaroService.schoolDetails = data;
      // this.driveCaroService.validateRoute();
      if (this.driveCaroService.validateRoute()) {
        this.driveCaroService.presentToast('Please complete your profile', 'danger');
        return this.router.navigateByUrl('/profile');
      } else if (!data.carList.items.filter((x) => !x._deleted).length) {
        this.driveCaroService.presentToast('Please add atleast one car', 'danger');
        return this.router.navigateByUrl(`/cars/true`);
      } else {
        this.driveCaroService.appLoaded.next(1);
        return this.router.navigateByUrl('/dashboard');
      }
    })
      .catch((err) => {
        console.log(err);
      });
  }
  logout() {
    this.auth.signOut();
    this.driveCaroService.webToken = '';
    this.router.navigateByUrl('/login');
    // window.location.reload();
  }
  login() {
    // console.log('logging out');
    // this.auth.signOut();
    // this.driveCaroService.webToken = '';
    // this.userData.logout().then(() => {
    return this.router.navigateByUrl('/login');
    // });
  }
}
