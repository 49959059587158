import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from "rxjs/operators";
// import 'rxjs/add/observable/throw';
// import 'rxjs/add/operator/catch';
import { DriveCaroService } from './drive-caro.service';
import { LoadingController } from '@ionic/angular';
const noSpinnerForAPI = ['autofill', 'savejob'];
@Injectable()
export class TokenInterceptorService {
    public spinnerCount = 0;
    loading: any;
    constructor(private _sessionInfoService: DriveCaroService,
        private loadingCtrl: LoadingController
        // private app: App,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('intercepted');
        // let loading: any = '';
        // const noSpinner = !noSpinnerForAPI.find(x => request.url.includes(x));
        // if (!this._sessionInfoService.showChatBubble && noSpinner) {
        this.showLoading();
        console.log('showing loader');
        // }
        this.spinnerCount += 1;
        let headers: any = '';
        if (!request.url.includes('maps.googleapis.com')) {
            headers = request.headers.set('Authorization', this._sessionInfoService.webToken);
        }
        request = request.clone({
            headers: headers
        });
        return next.handle(request).pipe(
            tap(
                event => { if (this.loading) this.loading.dismiss(); },
                error => {
                    console.log(error);
                    if (this.loading) this.loading.dismiss();
                }
            ),
            finalize(() => {
                if (this.loading)
                    this.loading.dismiss();
                console.log('disnuss loader');
                // console.log(JSON.stringify(next));
            })
        );
    }

    async showLoading() {
        this.loading = await this.loadingCtrl.create({
            message: 'loading...',
            duration: 1500,
        });

        this.loading.present();
    }
}