/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://m5ulcqofobge5mis6nsrwjc4xu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-south-1:ce9a309f-9a75-4d85-8ccd-71e7159cae51",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_lmtT604sZ",
    "aws_user_pools_web_client_id": "744hrkdp88s25r5j22t92tnsnt",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "drivecaro5f9cd55ff34f4aae814130bcf062539ae60a5-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
