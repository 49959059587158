// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  cognitoUserPoolId: 'ap-south-1_lmtT604sZ',
  cognitoAppClientId: '5ovgs0ohni8bkk64tuk3k23taf',
  cognitoRegion: 'ap-south-1',
  mapsKey: 'AIzaSyB5_20yGuMNE63tlN389yBh1IUEY9u3hJM'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
