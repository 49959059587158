import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DriveCaroService } from '../providers/drive-caro.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private driveCaroService: DriveCaroService, private router: Router) { };
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log('CanActivate called');
    let isLoggedIn = !!this.driveCaroService.webToken;
    if (isLoggedIn) {
      // this.driveCaroService.validateRoute();
      return true
    }
    else {
      this.router.navigate(['/login']);
    }
  }

}