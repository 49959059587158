import { ElementRef, Injectable } from '@angular/core';
import { APIService } from 'src/API.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Maps } from '../profile/profile.page';

@Injectable({
  providedIn: 'root'
})
export class DriveCaroService {
  // days: '30',
  // price: '4400',
  course: any;
  studentDetails: any;
  offlineCar: any;
  offlineStudent = {
    phone: '',
    email: '',
    firstName: '',
    id: ''
  };
  payment = {
    amountReceived: null,
    courseAmount: null,
    paymentDate: null,
    status: 'PENDING',
  }
  courseItem: any;
  courseDate: any;
  isCourseBooked: boolean = false;
  errorMessages: any = [];
  phone(phone: any, schoolId: string) {
    throw new Error('Method not implemented.');
  }
  public slotStatusMapper = {
    0: 'available',
    1: 'booked',
    2: 'bookedFemale',
    3: 'cancelled'
  }
  public scheduleStatusMapper = {
    1: 'PICKED',
    2: 'DRIVING',
    3: 'COMPLETED',
    4: 'DROPPED',
    0: 'PENDING'
  }
  loading: any;
  public appLoaded: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public webToken: string = '';
  public schoolId: string = '';
  public schools = [];
  public carList = [];
  public pilotList = [];
  allSchToArchive = [];
  slotAvaiExt: any = [];
  config = {
    bufferDays: 0,
  }

  schoolDetails: import("src/API.service").GetSchoolQuery;
  public searchElementRef: ElementRef;
  public readonly apiMap = this.load();
  constructor(
    private api: APIService, private http: HttpClient,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private router: Router,
  ) { }

  checkAppLoaded(): BehaviorSubject<number> {
    return this.appLoaded;
  }

  validateRoute() {
    return !this.schoolDetails.name || !this.schoolDetails.contactPerson
      || !this.schoolDetails.email || !this.schoolDetails.doorNumber
      || !this.schoolDetails.area || !this.schoolDetails.locality
      || !this.schoolDetails.pincode || !this.schoolDetails.landmark;
  }

  getCars() {
    // Query with filters, limits, and pagination
    const filter = {
      schoolCarListId: {
        eq: this.schoolId // filter priority = 1
      }
    };
    this.api.ListCars(filter).then((carList: any) => {
      console.log('carList list', carList);
      this.carList = carList.items.filter(x => !x._deleted);
      if (this.carList.length)
        this.carList[0].isSelected = true;
    })
      .catch((e) => {
        console.log('error getting cars...', e);
      });
  }

  createSchool(phone, userId) {
    const school = {
      id: userId,
      name: '',
      contactPerson: '',
      phone: phone,
      area: '',
      landmark: '',
      pincode: 0,
      staticMapAddress: ''
      // rating: '4',
      // distance: '4',
      // distanceFee: '4000',
      // kms: '5',
      // days: '30',
      // price: '4400',
    }
    this.api.CreateSchool(school).then(data => {
      this.schoolId = userId;
      this.schoolDetails = data;
      this.appLoaded.next(1);
      console.log('school created!', data);
    }).catch(e => {
      console.log('error', e);
    })
  }

  getRevGeo(body) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${body.latitude},${body.longitude}&key=${environment.mapsKey}`
    return this.http.get(url);
  }

  createUserIfNotExists(user) {
    const userId = user.attributes.sub;
    const phone = user.attributes.phone_number;
    this.api.GetSchool(userId).then(data => {
      if (data === null) {
        this.createSchool(phone, userId)
      } else {
        console.log(data);
        this.schoolDetails = data;
        this.appLoaded.next(1);
      }
    }).catch(e => {
      console.log(e);
      // if (e.data === null) {
      //   this.createUser(phone, userId)
      // }
    })
  }
  // getPilots() {
  //   // Query with filters, limits, and pagination
  //   const filter = {
  //     schoolPilotId: {
  //       eq: this.studentId // filter priority = 1
  //     }
  //   };
  //   this.api.ListPilots(filter).then((pilotList: any) => {
  //     console.log('pilot list', pilotList);
  //     this.pilotList = pilotList.items.filter(x => !x._deleted);
  //   })
  //     .catch((e) => {
  //       console.log('error getting pilots...', e);
  //     });
  // }
  async showLoading(loadMsg = 'Fetching data..') {
    console.log('loader', loadMsg);
    this.loading = await this.loadingCtrl.create({
      message: loadMsg,
      duration: 1000,
      spinner: 'crescent',
    });

    this.loading.present();
  }
  async presentToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: color === 'danger' ? 1500 : 1500,
      position: 'top',
      color: color
    });
    await toast.present();
  }

  private load(): Promise<Maps> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    // tslint:disable-next-line:no-bitwise
    const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
    script.src = this.getScriptSrc(callbackName);

    interface MyWindow { [name: string]: Function; };
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = resolve;
      script.onerror = reject;
    });
    document.body.appendChild(script);
    return promise.then(() => google.maps);
  }

  private getScriptSrc(callback: string): string {
    interface QueryParams { [key: string]: string; };
    const query: QueryParams = {
      v: '3',
      callback,
      key: 'AIzaSyAvHQwzrenXNxxyZJOv09e8qDhuQ4rGm6g',
      libraries: 'places',
      language: 'en',
      country: 'in'
    };
    const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    return `https://maps.googleapis.com/maps/api/js?${params}`;
  }
  updateSchoolLocation(lat = 40.545870, long = -74.389985, rad = 40) {
    const url = `https://yna0hjo911.execute-api.ap-south-1.amazonaws.com/prod/addSchool-Prod`;
    const payload = {
      "id": this.schoolId,
      "position": {
        "lat": lat,
        "lon": long
      },
      "name": this.schoolDetails.name,
      "address": this.schoolDetails.staticMapAddress,
      "phone": this.schoolDetails.phone
    }
    return this.http.put(url, payload);
  }
  // validateRoute() {
  //   const data = this.schoolDetails;
  //   if (!data.name || !data.contactPerson) {
  //     this.presentToast('Please complete your profile', 'danger');
  //     return this.router.navigateByUrl('/profile');
  //   } else if (!data.carList.items.filter((x) => !x._deleted).length) {
  //     this.presentToast('Please add atleast one car', 'danger');
  //     return this.router.navigateByUrl(`/cars/true`);
  //   } else {
  //     this.appLoaded.next(1);
  //     return this.router.navigateByUrl('/dashboard');
  //   }
  // }
  createUser(mbl) {
    const payload = {
      id: `+91${mbl}`
    }
    const url = `https://l6ri0iayf0.execute-api.ap-south-1.amazonaws.com/prod/createStudent-Prod`;
    return this.http.post(url, payload);
  }

  sendSms(payload) {
    console.log('payload for sms', payload);
    const url = `https://qcj6yl222g.execute-api.ap-south-1.amazonaws.com/prod/sendsms`;
    return this.http.post(url, payload);
  }

  convertToDate(dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split("/");
    let dat = d[2] + '-' + (d[0].length > 1 ? d[0] : '0' + d[0]) + '-' + (d[1].length > 1 ? d[1] : '0' + d[1]);
    return dat;
  }

  removeWords(inputString) {
    // Create regular expressions to match the words "school" and "driving" case-sensitively
    const schoolRegex = /\bschool\b/i;
    const drivingRegex = /\bdriving\b/i;
    const motorRegex = /\bmotor\b/i;

    // Replace any occurrences of the words "school" and "driving" with an empty string
    let result = inputString.replace(schoolRegex, '').replace(drivingRegex, '').replace(motorRegex, '');
    result = this.removeSpaces(result);
    return result;
  }

  removeSpaces(input) {
    return input.replace(/\s/g, '');
  }
  removeSeconds(inputString) {
    // Split the input string by ':'
    var parts = inputString.split(':');

    // Remove the last element from the array
    parts.pop();

    // Join the remaining parts with ':'
    var result = parts.join(':');
    this.removeSpaces(result);
    return result;
  }

  getAllSlotsByCourseId(course, filterItem = null, nextToken = null) {
    const courseFilter = filterItem || {
      courseCarScheduleId: {
        eq: course.id // filter priority = 1
      },
    };
    this.api.ListCarSchedules(courseFilter, 1000, nextToken).then(res => {
      if (res.nextToken) {
        console.log('next token available.. fetching results');
        this.allSchToArchive = [...this.allSchToArchive, ...res.items];
        this.getAllSlotsByCourseId(course, filterItem, res.nextToken);
        return;
      } else {
        this.allSchToArchive = [...this.allSchToArchive, ...res.items];
      }

      console.log('allSchToArchive', this.allSchToArchive);
      const totalSlots = this.allSchToArchive.length;
      this.allSchToArchive.forEach((item, index) => {
        this.archiveSlots(item);
        if (index === totalSlots - 1) {
          this.loading.dismiss();
        }
      })
    })
  }

  markSuspend(course, isSuspend = true, courseToBook = null) {
    let courseToUpdate: any = {
      id: course.id,
      _version: course._version,
      courseSuspended: isSuspend,
    }

    if (!isSuspend) {
      courseToUpdate.courseEnd = courseToBook.courseEnd;
      courseToUpdate.courseStart = courseToBook.courseStart;
      courseToUpdate.timeSlot = courseToBook.timeSlot;
      courseToUpdate.courseName = courseToBook.courseName;
      courseToUpdate.completedClasses = courseToBook.completedClasses;
    }
    this.showLoading(`Marking course as Suspended...Please don't click exit the app or click back button...!`);
    this.api.UpdateCourse(courseToUpdate).then(res => {
      console.log(res);
      this.course = res;
      if (isSuspend) {
        this.slotAvaiExt = [];
        this.updateSlotAvailable(res);
      }

      // this.getAllSlotsByCourseId(course);

    }).catch(e => {
      this.loading.dismiss();
      console.log('error', e);
    })
  }

  updateSlotAvailable(course, nextToken = null) {
    const slotAvailFilter = {
      timeSlot: {
        eq: course.timeSlot
      },
      schoolSlotAvailabilityId: {
        eq: course.school.id
      },
      carSlotAvailabilityId: {
        eq: course.car.id
      }
    }
    this.api.ListSlotAvailabilities(slotAvailFilter, 1000, nextToken).then(res => {
      this.slotAvaiExt = [...this.slotAvaiExt, ...res.items];
      if (res.nextToken) {
        console.log('next token available.. fetching results');
        this.updateSlotAvailable(course, res.nextToken);
        return;
      } else {
        console.log('ListSlotAvailabilities', this.slotAvaiExt);
        const sltAval: any = this.slotAvaiExt[0];
        const updateSlotAvail = {
          id: sltAval.id,
          isBooked: false,
          _version: sltAval._version
        }
        this.api.UpdateSlotAvailability(updateSlotAvail).then(res => {
          console.log(res);
          // this.triggerArchive();
          this.getAllSlotsByCourseId(course);
        }).catch(e => {
          this.presentToast(`Some error occured UpdateSlotAvailability! ${e.message}`, 'danger');
          this.loading.dismiss();
          console.log('error', e);
        })
      }
    }).catch(e => {
      console.log('error', e);
      this.presentToast(`Some error occured! ${e.message}`, 'danger');
      this.loading.dismiss();
    })
  }

  archiveSlots(selectedItem) {
    const archiveInput: any = {
      date: selectedItem.date,
      start: selectedItem.start,
      end: selectedItem.end,
      slotStatus: selectedItem.slotStatus,
      kmsDriven: selectedItem.kmsDriven,
      pickedTime: selectedItem.pickedTime,
      drivingStartedTime: selectedItem.drivingStartedTime,
      drivingCompletedTime: selectedItem.drivingCompletedTime,
      droppedTime: selectedItem.droppedTime,
      courseStatus: selectedItem.courseStatus,
      id: selectedItem.id,
      schoolScheduleArchiveId: selectedItem.schoolScheduleId,
      carScheduleArchiveId: selectedItem.carScheduleId,
      studentScheduleArchiveId: selectedItem.studentScheduleId,
      courseCarScheduleArchiveId: selectedItem.courseCarScheduleId,
    }
    if (selectedItem.addressCarScheduleId) {
      archiveInput.addressCarScheduleArchiveId = selectedItem.addressCarScheduleId
    }
    this.api.CreateCarScheduleArchive(archiveInput).then(res => {
      console.log(res);
      this.deleteSchedule(selectedItem);
    }).catch(e => {
      this.presentToast(`Some error occured! ${e.message}`, 'danger');
      console.log('error', e);
      this.loading.dismiss();
    })
  }

  deleteSchedule(selectedItem) {
    const deleteItem = {
      id: selectedItem.id,
      _version: selectedItem._version
    }
    this.api.DeleteCarSchedule(deleteItem).then(res => {
      console.log(res);
    }).catch(e => {
      this.loading.dismiss();
      this.presentToast(`Some error occured! ${e.message}`, 'danger');
      console.log('error', e);
    })
  }
}
